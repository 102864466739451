import React from 'react';
import { Link } from 'react-router-dom';

import PropTypes from 'prop-types';
import './Button.scss';

const Button = ({
  children,
  header,
  type,
  element,
  href,
  value,
  onClick,
  disabled = false,
}) => {
  const headerClass = header ? 'btn--header' : '';
  const bodyClass = header ? '' : 'btn--body';
  const typeClass = type === 'solid' ? 'btn--solid' : 'btn--outline';
  const disabledClass = disabled ? 'disabled' : '';
  let button;

  if (element === 'link')
    button = (
      <Link
        to={href}
        className={`btn ${headerClass} ${bodyClass} ${typeClass}`}
      >
        {children}
      </Link>
    );
  else if (element === 'input')
    button = (
      <input
        type="submit"
        value={value}
        disabled={disabled}
        className={`btn ${headerClass} ${bodyClass} ${typeClass} ${disabledClass}`}
      />
    );
  else if (element === 'button')
    button = (
      <button
        onClick={onClick}
        disabled={disabled}
        className={`btn ${headerClass} ${bodyClass} ${typeClass} ${disabledClass}`}
      >
        {children}
      </button>
    );

  return <>{button}</>;
};

export default Button;

Button.propTypes = {
  header: PropTypes.bool.isRequired,
  type: PropTypes.string.isRequired,
  element: PropTypes.string.isRequired,
  href: PropTypes.string,
  value: PropTypes.string,
};
