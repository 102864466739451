import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Chip from '@material-ui/core/Chip';
import Button from '../../../components/Button';

import './BlacklistForm.scss';

import cryptoSymbols from './seedData';

const symbolsValidationArray = cryptoSymbols.map(obj =>
  obj.label.toLowerCase(),
);

const BlacklistForm = ({ blacklistFromDB = [] }) => {
  const [blacklistInput, setBlacklistInput] = useState('');
  const [blacklistedCoins, setBlacklistedCoins] = useState([
    ...blacklistFromDB,
  ]);
  const handleSubmit = e => {
    e.preventDefault();
    // Logic will need to be changed once back end is implemented, this is just for front end dev purposes
    if (
      symbolsValidationArray.includes(blacklistInput) &&
      !blacklistedCoins.includes(blacklistInput)
    ) {
      setBlacklistedCoins(prevState => [...prevState, blacklistInput]);
      setBlacklistInput('');
    } else
      console.log(
        'COIN EITHER ALREADY BLACKLISTED OR SYMBOL IS NOT AVAILABLE ON BINANCE',
      );
  };

  const handleInputChange = e => {
    setBlacklistInput(e.target.value.toLowerCase());
  };

  const handleChipDelete = e => {
    const symbol = { toDelete: e.currentTarget.parentElement.dataset.symbol };
    if (blacklistedCoins.length === 1) setBlacklistedCoins([]);
    else {
      setBlacklistedCoins(prevState =>
        prevState.filter(coinSymbol => {
          return coinSymbol !== symbol.toDelete;
        }),
      );
    }
  };

  const renderChips = chipLabelArray =>
    chipLabelArray.length
      ? chipLabelArray.map(label => (
          <Chip
            key={label}
            size="medium"
            label={label.toUpperCase()}
            clickable={true}
            onDelete={handleChipDelete}
            className={`blacklist-form__chip ${label}`}
            data-symbol={label}
          />
        ))
      : null;

  return (
    <div className="blacklist-form custom-mui-form">
      <form onSubmit={handleSubmit} className="custom-mui-form__form">
        <TextField
          id="coin-blacklist-input"
          type="text"
          variant="standard"
          label="Coin Symbol"
          fullWidth={true}
          value={blacklistInput}
          name="coin-blacklist"
          onChange={handleInputChange}
        />
        <Button type="solid" header={false} element="input" value="Blacklist" />
      </form>
      <div className="blacklist-form__chip-display">
        {renderChips(blacklistedCoins)}
      </div>
    </div>
  );
};

export default BlacklistForm;
