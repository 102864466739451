import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { HashLink } from 'react-router-hash-link';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { toggleDashMenuOpen } from '../../redux/DashMenu/dash-menu.actions';
import SelectInput from '../SelectInput';
import MenuIcon from './MenuIcon';
import Button from '../../components/Button';
import TextLogo from '../../static/img/text-logo.png';
import BusinessSamurai from '../../static/img/business-samurai.png';

import './Header.scss';

const Header = ({
  location,
  isMenuOpen,
  handleMenuToggle,
  setIsMenuOpen,
  setRole,
  role,
  toggleDashMenuOpen,
  isDashMenuOpen,
}) => {
  // State
  const [isTopOfPage, setIsTopOfPage] = useState(true);

  // Methods
  const handleEnterTopOfPage = () => setIsTopOfPage(true);
  const handleLeaveTopOfPage = () => setIsTopOfPage(false);

  const handleNavLinkClick = () => {
    if (isMenuOpen) handleMenuToggle();
  };

  const handleLogoClick = () => {
    if (isMenuOpen) handleMenuToggle();
  };

  const getFirstPath = pathname => {
    const [, path] = pathname.split('/');
    return path;
  };

  // Class variables
  const headerFixedClass = !isTopOfPage ? 'header-fixed' : '';
  const menuOpenClass = isMenuOpen ? 'menu-open' : '';
  const headerBgColor =
    getFirstPath(location.pathname) === 'dashboard' ? 'header--black' : '';

  useEffect(() => {
    const handleBodyOverflowOnResize = e => {
      if (e.currentTarget.innerWidth >= 900) setIsMenuOpen(false);
    };

    const handleScroll = e => {
      const verticalScrollPos = e.currentTarget.scrollY;
      if (verticalScrollPos > 0) handleLeaveTopOfPage();
      else handleEnterTopOfPage();
    };

    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleBodyOverflowOnResize);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleBodyOverflowOnResize);
    };
  }, [setIsMenuOpen]);

  const currentRoute = location.pathname;
  const getFirstRouteName = location.pathname.split('/')[1];

  const renderLogo = () => {
    if (currentRoute === '/')
      return (
        <HashLink onClick={handleLogoClick} smooth to="/#top">
          <img
            className="header__logo"
            src={TextLogo}
            alt="Altcoin Ninjas' logo"
          />
        </HashLink>
      );
    else if (getFirstRouteName === 'dashboard')
      return (
        <HashLink onClick={handleLogoClick} to="/dashboard">
          <img
            className="header__logo"
            src={TextLogo}
            alt="Altcoin Ninjas' logo"
          />
        </HashLink>
      );
    else
      return (
        <HashLink onClick={handleLogoClick} to="/#top">
          <img
            className="header__logo"
            src={TextLogo}
            alt="Altcoin Ninjas' logo"
          />
        </HashLink>
      );
  };
  document.body.style.overflow = isMenuOpen ? 'hidden' : 'auto';
  document.documentElement.style.overflowY = isMenuOpen ? 'hidden' : 'auto';

  return (
    <header className={`header ${headerBgColor}  ${headerFixedClass}`}>
      {renderLogo()}

      <nav className="header__nav">
        <ul className={`${menuOpenClass}`}>
          <div className={`header__nav-links-left ${menuOpenClass}`}>
            <li onClick={handleNavLinkClick}>
              <Link rel="prerender" to="/about-us">
                About Us
              </Link>
            </li>
            <li onClick={handleNavLinkClick}>
              <Link rel="prerender" to="/guide">
                Guide
              </Link>
            </li>
            <li onClick={handleNavLinkClick}>
              <Link rel="prerender" to="/contact-us">
                Contact
              </Link>
            </li>
          </div>
          <div className={`header__nav-links-right ${menuOpenClass}`}>
            {location.pathname.split('/')[1].toLowerCase() === 'dashboard' ? (
              <li onClick={handleNavLinkClick}>
                <Button
                  header={true}
                  type="solid"
                  element="link"
                  href="/auth/login"
                >
                  Logout
                </Button>
              </li>
            ) : (
              <>
                <li onClick={handleNavLinkClick}>
                  <Button
                    header={true}
                    type="outline"
                    element="link"
                    href="/auth/login"
                  >
                    Login
                  </Button>
                </li>
                <li onClick={handleNavLinkClick}>
                  <Button
                    header={true}
                    type="solid"
                    element="link"
                    href="/auth/register"
                  >
                    Register
                  </Button>
                </li>
              </>
            )}
          </div>
          <img
            src={BusinessSamurai}
            alt="Cartoon Altcoin Ninja in a suit"
            className={`header__menu-img ${menuOpenClass}`}
          />
        </ul>
      </nav>
      {location.pathname.split('/')[1].toLowerCase() === 'dashboard' ? (
        <>
          <SelectInput
            menuItemArray={['user', 'signaler', 'superAdmin']}
            value={role}
            label="role"
            name="role"
            id="role-select"
            onChange={setRole}
          />

          <MenuIcon
            type="dashboard"
            handleMenuToggle={toggleDashMenuOpen}
            isMenuOpen={isDashMenuOpen}
          />
        </>
      ) : (
        <MenuIcon
          type="public"
          handleMenuToggle={handleMenuToggle}
          isMenuOpen={isMenuOpen}
        />
      )}
    </header>
  );
};

Header.propTypes = {
  location: PropTypes.object.isRequired,
};

const mapStateToProps = ({ dashMenu: { isOpen } }) => ({
  isDashMenuOpen: isOpen,
});

const mapDispatchToProps = dispatch => ({
  toggleDashMenuOpen: () => dispatch(toggleDashMenuOpen()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Header));
