import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

import CryptoSlider from './CryptoSlider';

import './ExchangeRate.scss';

const coinArray = ['btc', 'ltc', 'eth', 'xrp', 'xmr', 'eos'];

const ExchangeRate = () => {
  const [isHeadlineEntered, setIsHeadlineEntered] = useState(false);

  const handleEnterHeadline = () => setIsHeadlineEntered(true);

  const revealHeadlineClass = isHeadlineEntered ? 'reveal' : '';

  return (
    <section className="exchange-rate">
      <Waypoint onEnter={handleEnterHeadline} bottomOffset="50%">
        <h2 className={`section-headline hide ${revealHeadlineClass}`}>
          Crypto Exchange Rate
        </h2>
      </Waypoint>

      <CryptoSlider coinArray={coinArray} />
    </section>
  );
};

export default ExchangeRate;
