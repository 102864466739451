import React, { useState } from 'react';

import { Route, Switch } from 'react-router-dom';

import ScrollToTop from './components/ScrollToTop';
import Header from './components/Header';
import Footer from './components/Footer';
import Home from './scenes/Home';
import AboutUs from './scenes/AboutUs';
import Guide from './scenes/Guide';
import ContactUs from './scenes/ContactUs';
import Auth from './scenes/Auth';
import Dashboard from './components/Dashboard';

import 'normalize.css';
import './App.scss';

const App = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [role, setRole] = useState('superAdmin');

  const handleRoleChange = e => setRole(e.target.value);

  const handleMenuToggle = () => setIsMenuOpen(prevState => !prevState);

  return (
    <div className="App">
      <Header
        isMenuOpen={isMenuOpen}
        handleMenuToggle={handleMenuToggle}
        setIsMenuOpen={setIsMenuOpen}
        setRole={handleRoleChange}
        role={role}
      />
      <ScrollToTop />
      <Switch>
        <Route exact path="/" component={Home} />
        <Route path="/about-us" component={AboutUs} />
        <Route
          path="/guide"
          component={() => <Guide isAppMenuOpen={isMenuOpen} />}
        />
        <Route path="/contact-us" component={ContactUs} />
        <Route path="/auth/:form" component={Auth} />
        <Route path="/dashboard" component={() => <Dashboard role={role} />} />
      </Switch>
      <Footer />
    </div>
  );
};

export default App;
