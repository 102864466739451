import React from 'react';
import { Waypoint } from 'react-waypoint';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

import { toggleDashMenuFixed } from '../../redux/DashMenu/dash-menu.actions';
import FooterLogo from '../../static/img/footer-logo.png';

import './Footer.scss';

const Footer = ({ toggleDashMenuFixed }) => (
  <Waypoint
    onEnter={toggleDashMenuFixed}
    onLeave={toggleDashMenuFixed}
    bottomOffset="10%"
  >
    <footer className="footer">
      <div className="footer__layout">
        <Link to="/">
          <img
            src={FooterLogo}
            alt="Altcoin Ninjas logo"
            className="footer__logo"
          />
        </Link>
        <div className="footer__content">
          <nav>
            <ul className="footer__nav-list">
              <li>
                <Link to="/about-us">About Us</Link>
              </li>
              <li>
                <Link to="/legal#terms-and-conditions">
                  Terms And Conditions
                </Link>
              </li>
              <li>
                <Link to="/legal#privacy-policy">Privacy Policy</Link>
              </li>
              <li>
                <Link to="/contact-us">Contact Us</Link>
              </li>
            </ul>
          </nav>
          <p className="footer__mission-statement">
            Global leaders of cryptocurrency in terms of education , finance,
            and retail. Empowering and improving the lives of individuals while
            making a positive impact in our community.
          </p>
          <span className="footer__copyright">{`© ${new Date().getFullYear()} Altcoin Ninjas`}</span>
        </div>
      </div>
    </footer>
  </Waypoint>
);

const mapDispatchToProps = dispatch => ({
  toggleDashMenuFixed: () => dispatch(toggleDashMenuFixed()),
});

export default connect(
  null,
  mapDispatchToProps,
)(Footer);
