import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Avatar,
  TextField,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';
import {
  CheckBox as CheckBoxIcon,
  CheckBoxOutlineBlank as CheckBoxOutlineBlankIcon,
} from '@material-ui/icons';

import Button from '../../Button';
import { toggleMessageModal } from '../../../redux/MessageModal/message-modal.actions';
import { openSnackbar } from '../../../redux/Snackbar/snackbar.actions';

import './MessageModal.scss';

const initialState = {
  email: false,
  discord: false,
  telegram: false,
  username: '',
  message: '',
};

const MessageModal = ({
  user,
  toggleMessageModal,
  messageModalIsOpen,
  openSnackbar,
}) => {
  const [messageDetails, setMessageDetails] = useState(initialState);

  const handleFormSubmit = e => {
    e.preventDefault();
    setMessageDetails(initialState);
    openSnackbar('Message sent');
    toggleMessageModal();
  };

  const handleTextInputChange = e => {
    const { value, name } = e.target;
    setMessageDetails(prevState => ({ ...prevState, [name]: value }));
  };

  const handleCheckboxInputChange = e => {
    const { name } = e.target;
    setMessageDetails(prevState => ({
      ...prevState,
      [name]: !prevState[name],
    }));
  };

  useEffect(() => {
    const handleClickOnBackground = e => {
      const { classList } = e.target;

      if (classList.contains('dashboard') && messageModalIsOpen)
        toggleMessageModal();
    };

    window.addEventListener('click', handleClickOnBackground);

    return () => {
      window.removeEventListener('click', handleClickOnBackground);
    };
  }, [messageModalIsOpen, toggleMessageModal]);

  return (
    <div className="message-modal">
      <div className="message-modal__avatar-group">
        <Avatar
          src={user.avatar.src}
          alt="profile avatar"
          className="message-modal__avatar"
        />
        <div className="message-modal__avatar-status" />
        <span className="message-modal__username">{user.username}</span>
      </div>
      <form
        onSubmit={handleFormSubmit}
        className="message-modal__form custom-mui-form"
      >
        <TextField
          id="notify-user-message-input"
          type="text"
          variant="standard"
          label="message"
          fullWidth={true}
          name="message"
          multiline={true}
          onChange={handleTextInputChange}
          value={messageDetails.message}
        />
        <div className="message-modal__checkbox-group">
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxIcon fontSize="large" />}
                value={messageDetails.email}
                name="email"
                onChange={handleCheckboxInputChange}
                disabled={!user.notifications.email.isPreferred}
              />
            }
            label="Email"
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxIcon fontSize="large" />}
                value={messageDetails.discord}
                name="discord"
                onChange={handleCheckboxInputChange}
                disabled={!user.notifications.discord.isPreferred}
              />
            }
            label="Discord"
          />
          <FormControlLabel
            control={
              <Checkbox
                icon={<CheckBoxOutlineBlankIcon fontSize="large" />}
                checkedIcon={<CheckBoxIcon fontSize="large" />}
                value={messageDetails.telegram}
                name="telegram"
                onChange={handleCheckboxInputChange}
                disabled={!user.notifications.telegram.isPreferred}
              />
            }
            label="Telegram"
          />
        </div>
        <Button type="solid" header={false} element="input" value="send" />
      </form>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleMessageModal: () => dispatch(toggleMessageModal()),
  openSnackbar: message => dispatch(openSnackbar(message)),
});

export default connect(
  null,
  mapDispatchToProps,
)(MessageModal);
