const usersArray = ['user2289', 'cryptoshark', 'kincoin44', 'udownwithbtc'];

const orderTypeArray = [
  'market',
  'limit',
  'stop loss',
  'stop loss limit',
  'take profit',
  'take profit limit',
  'limit maker',
];

const tradePairArray = [
  'BTC/USDT',
  'ETH/USDT',
  'EOS/USDT',
  'ETH/BTC',
  'BCHABC/USDT',
  'LINK/BTC',
  'LTC/USDT',
  'XRP/USDT',
  'BNB/BTC',
  'BTC/USDC',
  'BNB/USDT',
  'XRP/BTC',
  'LTC/BTC',
  'BTC/PAX',
  'BCHABC/BTC',
  'EOS/BTC',
  'LINK/USDT',
  'BTC/TUSD',
  'TRX/USDT',
  'USDC/USDT',
  'MATIC/BTC',
  'TRX/BTC',
  'REN/BTC',
  'PAX/USDT',
  'TUSD/USDT',
  'ADA/BTC',
  'ERD/BTC',
  'ADA/USDT',
  'NEO/USDT',
  'WTC/BTC',
  'BAT/BTC',
  'BTT/USDT',
  'ONE/BTC',
  'CELR/BTC',
  'MATIC/USDT',
  'ONT/BTC',
  'ONT/USDT',
  'QTUM/USDT',
  'WAVES/BTC',
  'FTM/BTC',
  'ETH/USDC',
  'ALGO/USDT',
  'IOST/BTC',
  'ICX/BTC',
  'XMR/BTC',
  'XLM/BTC',
  'NEO/BTC',
  'ETC/USDT',
  'DASH/BTC',
  'BNB/ETH',
  'QTUM/BTC',
  'ETH/PAX',
  'ZEC/BTC',
  'ATOM/BTC',
  'THETA/BTC',
  'EOS/ETH',
  'CMT/BTC',
  'LINK/ETH',
  'ONE/USDT',
  'XLM/USDT',
  'BTT/BTC',
  'RVN/BTC',
  'XRP/PAX',
  'ALGO/BTC',
  'ICX/USDT',
  'ZIL/BTC',
  'EOS/PAX',
  'DENT/BTC',
  'LTC/PAX',
  'ATOM/USDT',
  'BAT/USDT',
  'KMD/BTC',
  'RCN/BTC',
  'VET/USDT',
  'ERD/USDT',
  'EOS/BNB',
  'XRP/BNB',
  'XRP/ETH',
  'BQX/BTC',
  'LTC/BNB',
  'BCHABC/PAX',
  'NANO/BTC',
  'ETC/BTC',
  'IOST/USDT',
  'TRX/ETH',
  'ETH/TUSD',
  'VET/BTC',
  'FET/USDT',
  'QKC/BTC',
  'IOTA/BTC',
  'FUEL/BTC',
  'XRP/USDC',
  'FET/BTC',
  'BNB/USDC',
  'ZEC/USDT',
  'CELR/USDT',
  'XVG/BTC',
  'TNT/BTC',
  'DOCK/BTC',
  'IOTA/USDT',
  'CVC/BTC',
  'ZIL/USDT',
  'FTM/USDT',
  'USDC/TUSD',
  'OMG/BTC',
  'XEM/BTC',
  'ERD/BNB',
  'WAVES/USDT',
  'NPXS/BTC',
  'DOGE/BTC',
  'HOT/USDT',
  'HOT/BTC',
  'LTC/USDC',
  'GNT/BTC',
  'ZRX/BTC',
  'XMR/USDT',
  'ENJ/BTC',
  'PHB/BTC',
  'PAX/TUSD',
  'OMG/USDT',
  'AMB/BTC',
  'CDT/BTC',
  'MTL/BTC',
  'NPXS/ETH',
  'ELF/BTC',
  'NAS/BTC',
  'REP/BTC',
  'TRX/BNB',
  'LINK/USDC',
  'BAT/ETH',
  'LTC/ETH',
  'MATIC/BNB',
  'ONE/BNB',
  'VIA/BTC',
  'USDC/PAX',
  'FUEL/ETH',
  'AST/BTC',
  'TNB/BTC',
  'HOT/ETH',
  'PPT/BTC',
  'IOST/ETH',
  'MCO/BTC',
  'HC/BTC',
  'BCHABC/USDC',
  'TRX/XRP',
  'EOS/USDC',
  'BTT/BNB',
  'LTC/TUSD',
  'NANO/USDT',
  'WAN/BTC',
  'BCHABC/TUSD',
  'ADA/ETH',
  'AE/BTC',
  'THETA/USDT',
  'MANA/BTC',
  'BTG/BTC',
  'IOTX/BTC',
  'DOGE/USDT',
  'NEO/ETH',
  'ADA/BNB',
  'NULS/BTC',
  'DASH/USDT',
  'STRAT/BTC',
  'ARN/BTC',
  'BNB/TUSD',
  'BTC/USDS',
  'XRP/TUSD',
  'SC/BTC',
  'BNB/PAX',
  'TFUEL/BTC',
  'ICX/ETH',
  'LSK/BTC',
  'AION/BTC',
  'DENT/ETH',
  'POLY/BTC',
  'LINK/TUSD',
  'ENG/BTC',
  'POE/BTC',
  'ONT/ETH',
  'MFT/BTC',
  'LUN/BTC',
  'REN/BNB',
  'CND/BTC',
  'WABI/BTC',
  'BTS/BTC',
  'INS/BTC',
  'GXS/BTC',
  'NAV/BTC',
  'STORM/BTC',
  'VIBE/BTC',
  'RDN/BTC',
  'GTO/BTC',
  'TFUEL/USDT',
  'BAT/BNB',
  'ICX/BNB',
  'OMG/ETH',
  'AGI/BTC',
  'GVT/BTC',
  'ONT/BNB',
  'FTM/BNB',
  'DGD/BTC',
  'NCASH/BTC',
  'BCPT/BTC',
  'MITH/BTC',
  'KNC/BTC',
  'VET/ETH',
  'BCD/BTC',
  'TRX/USDC',
  'QTUM/ETH',
  'ADA/TUSD',
  'NEO/BNB',
  'THETA/ETH',
  'WAVES/ETH',
  'MTH/BTC',
  'OAX/BTC',
  'SKY/BTC',
  'ZRX/USDT',
  'MDA/BTC',
  'ARK/BTC',
  'WTC/ETH',
  'YOYO/BTC',
  'CELR/BNB',
  'ENJ/USDT',
  'CMT/ETH',
  'EVX/BTC',
  'GO/BTC',
  'SYS/BTC',
  'ADA/USDC',
  'ZIL/ETH',
  'LOOM/BTC',
  'VIB/BTC',
  'EOS/TUSD',
  'LINK/PAX',
  'ONG/BTC',
  'POWR/BTC',
  'SNM/BTC',
  'ARDR/BTC',
  'ATOM/BNB',
  'ZEC/ETH',
  'NEBL/BTC',
  'RCN/ETH',
  'DCR/BTC',
  'ALGO/BNB',
  'XLM/ETH',
  'STEEM/BTC',
  'BLZ/BTC',
  'STORJ/BTC',
  'KMD/ETH',
  'XVG/ETH',
  'USDS/USDT',
  'QLC/BTC',
  'TRX/TUSD',
  'DOGE/BNB',
  'XZC/BTC',
  'VET/BNB',
  'MITH/USDT',
  'NULS/USDT',
  'DASH/ETH',
  'BTT/USDC',
  'DNT/BTC',
  'WPR/BTC',
  'PIVX/BTC',
  'WAVES/BNB',
  'IOTA/ETH',
  'KNC/ETH',
  'RVN/BNB',
  'RLC/BTC',
  'FUN/BTC',
  'QTUM/BNB',
  'ONG/USDT',
  'GRS/BTC',
  'IOST/BNB',
  'LEND/BTC',
  'DLT/BTC',
  'SKY/BNB',
  'HOT/BNB',
  'SNT/BTC',
  'IOTA/BNB',
  'ZIL/BNB',
  'GAS/BTC',
  'LRC/BTC',
  'DATA/BTC',
  'QKC/ETH',
  'CMT/BNB',
  'POA/BTC',
  'XMR/ETH',
  'BRD/BTC',
  'BAT/USDC',
  'SC/ETH',
  'QSP/BTC',
  'ENG/ETH',
  'ZRX/ETH',
  'ZEC/BNB',
  'NEO/USDC',
  'XLM/USDC',
  'ETC/ETH',
  'APPC/BTC',
  'NANO/ETH',
  'WTC/BNB',
  'SNGLS/BTC',
  'USDS/USDC',
  'KEY/BTC',
  'OST/BTC',
  'TNT/ETH',
  'MCO/ETH',
  'ALGO/USDC',
  'MTL/ETH',
  'ZEN/BTC',
  'MFT/ETH',
  'ADX/BTC',
  'BRD/BNB',
  'IOTX/ETH',
  'TFUEL/BNB',
  'REP/ETH',
  'ETC/BNB',
  'WAN/ETH',
  'EDO/BTC',
  'CVC/ETH',
  'TRX/PAX',
  'BNB/USDS',
  'FET/BNB',
  'XLM/BNB',
  'ENJ/ETH',
  'AE/ETH',
  'NAS/ETH',
  'DOCK/ETH',
  'NEO/TUSD',
  'NANO/BNB',
  'THETA/BNB',
  'EVX/ETH',
  'MITH/BNB',
  'AMB/ETH',
  'TNB/ETH',
  'NXS/BTC',
  'XEM/ETH',
  'STORM/ETH',
  'INS/ETH',
  'REQ/BTC',
  'XMR/BNB',
  'ENJ/BNB',
  'PPT/ETH',
  'DGD/ETH',
  'DASH/BNB',
  'XEM/BNB',
  'RCN/BNB',
  'GNT/ETH',
  'BNT/BTC',
  'GTO/USDT',
  'SC/BNB',
  'ARN/ETH',
  'BTG/ETH',
  'NEO/PAX',
  'VIA/ETH',
  'ADA/PAX',
  'NAS/BNB',
  'HC/ETH',
  'RDN/ETH',
  'AION/ETH',
  'BQX/ETH',
  'LOOM/ETH',
  'ONE/USDC',
  'MANA/ETH',
  'MFT/BNB',
  'CDT/ETH',
  'XLM/TUSD',
  'LSK/BNB',
  'BTT/TUSD',
  'PHB/BNB',
  'SKY/ETH',
  'WAN/BNB',
  'ELF/ETH',
  'LSK/ETH',
  'ZRX/BNB',
  'BCD/ETH',
  'STORM/BNB',
  'GXS/ETH',
  'ERD/PAX',
  'OMG/BNB',
  'ALGO/PAX',
  'STRAT/ETH',
  'SYS/ETH',
  'USDS/PAX',
  'USDS/TUSD',
  'AGI/ETH',
  'LRC/ETH',
  'XLM/PAX',
  'NCASH/ETH',
  'AST/ETH',
  'GVT/ETH',
  'ZEC/USDC',
  'BAT/PAX',
  'ONE/TUSD',
  'BAT/TUSD',
  'POE/ETH',
  'ERD/USDC',
  'LUN/ETH',
  'SNM/ETH',
  'BRD/ETH',
  'BLZ/ETH',
  'STORJ/ETH',
  'CND/ETH',
  'CVC/BNB',
  'BTS/ETH',
  'AGI/BNB',
  'CND/BNB',
  'SNT/ETH',
  'ZEC/TUSD',
  'AMB/BNB',
  'LEND/ETH',
  'VIB/ETH',
  'NAV/ETH',
  'MCO/BNB',
  'ARK/ETH',
  'ATOM/USDC',
  'ETC/USDC',
  'ALGO/TUSD',
  'ARDR/ETH',
  'GNT/BNB',
  'ONE/PAX',
  'NULS/ETH',
  'YOYO/ETH',
  'MDA/ETH',
  'POA/ETH',
  'MTH/ETH',
  'ATOM/TUSD',
  'POA/BNB',
  'ZEC/PAX',
  'WAVES/USDC',
  'GTO/BNB',
  'NEBL/BNB',
  'POWR/ETH',
  'NEBL/ETH',
  'PIVX/ETH',
  'VIBE/ETH',
  'OAX/ETH',
  'RLC/ETH',
  'XZC/XRP',
  'ONG/BNB',
  'LOOM/BNB',
  'POLY/BNB',
  'REP/BNB',
  'YOYO/BNB',
  'STEEM/ETH',
  'GTO/ETH',
  'XZC/ETH',
  'AE/BNB',
  'VIA/BNB',
  'NULS/BNB',
  'FUN/ETH',
  'BLZ/BNB',
  'SNGLS/ETH',
  'FTM/PAX',
  'SYS/BNB',
  'QSP/ETH',
  'DATA/ETH',
  'AION/BNB',
  'BCPT/ETH',
  'PHB/TUSD',
  'WABI/BNB',
  'WABI/ETH',
  'REQ/ETH',
  'BNT/ETH',
  'RDN/BNB',
  'DOGE/USDC',
  'FTM/TUSD',
  'BCPT/BNB',
  'FTM/USDC',
  'USDSB/USDT',
  'GO/BNB',
  'ARDR/BNB',
  'ATOM/PAX',
  'KEY/ETH',
  'ETC/PAX',
  'GRS/ETH',
  'NAV/BNB',
  'STEEM/BNB',
  'DCR/BNB',
  'WPR/ETH',
  'OST/ETH',
  'DNT/ETH',
  'ADX/ETH',
  'XZC/BNB',
  'ZEN/ETH',
  'NCASH/BNB',
  'EDO/ETH',
  'QLC/ETH',
  'RLC/BNB',
  'DLT/BNB',
  'DLT/ETH',
  'BTT/PAX',
  'ETC/TUSD',
  'USDSB/USDS',
  'WAVES/TUSD',
  'POWR/BNB',
  'APPC/BNB',
  'APPC/ETH',
  'BTS/BNB',
  'BTCB/BTC',
  'ZEN/BNB',
  'QLC/BNB',
  'PIVX/BNB',
  'TFUEL/TUSD',
  'NXS/BNB',
  'QSP/BNB',
  'WAVES/PAX',
  'OST/BNB',
  'GTO/USDC',
  'NXS/ETH',
  'PHB/PAX',
  'PHB/USDC',
  'DOGE/PAX',
  'TFUEL/USDC',
  'ADX/BNB',
  'BCPT/PAX',
  'TFUEL/PAX',
  'BCPT/USDC',
  'BCPT/TUSD',
  'GTO/PAX',
  'PHX/BTC',
  'PHX/BNB',
  'PHX/ETH',
  'GTO/TUSD',
];

const userProfileData = {
  riskPerTrade: 'high',
  pairingPreference: 'top coins',
  rateOfReturn: 'short term',
};

export { usersArray, userProfileData, tradePairArray, orderTypeArray };
