import React, { useState } from 'react';

import SelectInput from '../../components/SelectInput';
import RegistrationChart from './RegistrationChart';
import TableDisplay from '../../components/Dashboard/TableDisplay';
import RegisterSignalerForm from './RegisterSignalerForm';

import { ReactComponent as RegistrationIcon } from '../../static/svg/registration-icon.svg';
import { ReactComponent as ActivityIcon } from '../../static/svg/psi-icon.svg';
import { ReactComponent as LinkIcon } from '../../static/svg/link-icon.svg';

import DashComponent from '../../components/Dashboard/DashComponent';

import './DashActivity.scss';

import { registrationDataSet, activityUserList } from './seedData';

const roleItemArray = ['user', 'signaler'];

const statusItemArrays = {
  user: [
    'active',
    'inactive',
    'logged in',
    'logged out',
    'locked',
    'eligible',
    'ineligible',
  ],
  signaler: ['active', 'inactive', 'logged in', 'logged out', 'locked'],
};

const DashActivity = ({ handleToggleMessageModal }) => {
  const [roleSelectInput, setRoleSelectInput] = useState('user');
  const [statusSelectInput, setStatusSelectInput] = useState('active');
  const [statusMenuItemsArray, setStatusMenuItemsArray] = useState(
    statusItemArrays.user,
  );

  const handleRoleChange = e => {
    setRoleSelectInput(e.target.value);
    setStatusMenuItemsArray(statusItemArrays[e.target.value]);
    setStatusSelectInput(statusItemArrays[e.target.value][0]);
  };

  const handleStatusChange = e => setStatusSelectInput(e.target.value);

  return (
    <section className="dash-activity">
      <DashComponent svg={<RegistrationIcon />} title="registration">
        <RegistrationChart data={registrationDataSet} />
      </DashComponent>
      <DashComponent svg={<ActivityIcon />} title="activity">
        <div className="activity-table">
          <div className="activity-table__inputs">
            <div className="activity-table__count">
              <span className="activity-table__count-label">
                {statusSelectInput}
              </span>
              <span className="activity-table__count-number">25</span>
            </div>
            <SelectInput
              value={roleSelectInput}
              onChange={handleRoleChange}
              menuItemArray={roleItemArray}
              id="dash-activity-role-input"
              name="roleSelectInput"
              label="Role"
            />
            <SelectInput
              value={statusSelectInput}
              onChange={handleStatusChange}
              menuItemArray={statusMenuItemsArray}
              id="dash-activity-status-input"
              name="statusSelectInput"
              label="Status"
            />
          </div>
          <TableDisplay
            numPages={5}
            headerTitleArray={['username', 'login', 'registered', '']}
            removeHeaderAtSmallArray={['username', 'login', 'registered']}
            tableRowsData={activityUserList}
            type="activity"
            paginationPath="/dashboard/activity-and-notifications"
            handleToggleModal={handleToggleMessageModal}
          />
        </div>
      </DashComponent>
      <DashComponent svg={<LinkIcon />} title="register signaler">
        <RegisterSignalerForm />
      </DashComponent>
    </section>
  );
};

export default DashActivity;
