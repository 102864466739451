import AvatarImg from '../../static/img/avatar.jpg';
import AvatarImg2 from '../../static/img/avatar-2.jpg';
import AvatarImg3 from '../../static/img/avatar-3.jpg';
import AvatarImg4 from '../../static/img/avatar-4.jpg';
import AvatarImg5 from '../../static/img/hooded-ninja.jpg';

const date = 1562011505000;

const createRegistrationData = () => {
  const data = [];
  for (let i = 0; i <= 24; i++) {
    data.push({
      x: date + i * 108000000,
      y: Math.floor(20 * i + Math.random() * 40),
    });
  }

  return data;
};

const registrationData = createRegistrationData();

const registrationDataSet = [
  {
    id: 'Number of User Registrations',
    data: registrationData,
  },
];

const activityUserList = [
  {
    username: 'signaler76',
    src: AvatarImg,
    alt: 'profile avatar',
    lastLogin: '06/11/2019',
    registrationDate: '04/17/2018',
  },
  {
    username: '51gn413r',
    src: AvatarImg2,
    alt: 'profile avatar',
    lastLogin: '06/11/2019',
    registrationDate: '07/2/2017',
  },
  {
    username: 'coindexter',
    src: AvatarImg3,
    alt: 'profile avatar',
    lastLogin: '06/11/2019',
    registrationDate: '04/30/2019',
  },
  {
    username: 'udownwithbtc',
    src: AvatarImg4,
    alt: 'profile avatar',
    lastLogin: '06/11/2019',
    registrationDate: '10/01/2018',
  },
  {
    username: 'signalninja',
    src: AvatarImg5,
    alt: 'profile avatar',
    lastLogin: '06/11/2019',
    registrationDate: '12/17/2018',
  },
];

export { registrationDataSet, activityUserList };
