import React, { useState } from 'react';

import BeginForm from './BeginForm';
import QuestionForm from './QuestionForm';

import questionFormData from './seedData';

import './TradeProfile.scss';

const TradeProfile = () => {
  const [inputFields, setInputFields] = useState({
    riskPerTrade: '',
    pairingPreference: '',
    rateOfReturn: '',
  });

  const [formProgress, setFormProgress] = useState({
    page: 0,
    questionsAnswered: 0,
    profileComplete: false,
  });

  const handleRadioGroupChange = e => {
    const event = { ...e };

    setInputFields(prevState => ({
      ...prevState,
      [event.target.name]: event.target.value,
    }));

    if (!inputFields[event.target.name])
      if (formProgress.page < 4 && formProgress.questionsAnswered === 2)
        setFormProgress(prevState => ({
          ...prevState,
          questionsAnswered: prevState.questionsAnswered + 1,
          page: 4,
        }));
      else
        setFormProgress(prevState => ({
          ...prevState,
          questionsAnswered: prevState.questionsAnswered + 1,
        }));
  };

  const handleTradeProfileNavButton = e => {
    e.persist();
    if (e.target.dataset.direction === 'next' && formProgress.page < 4)
      setFormProgress(prevState => ({
        ...prevState,
        page: prevState.page + 1,
      }));
    else if (e.target.dataset.direction === 'previous')
      setFormProgress(prevState => ({
        ...prevState,
        page: prevState.page - 1,
      }));
  };

  const handleBeginClick = e =>
    setFormProgress(prevState => ({ ...prevState, page: prevState.page + 1 }));

  const renderContent = formProgress => {
    if (formProgress.page === 0)
      return (
        <BeginForm
          formProgress={formProgress}
          handleBeginClick={handleBeginClick}
        />
      );
    else if (formProgress.page === 1)
      return (
        <QuestionForm
          inputFields={inputFields}
          formProgress={formProgress}
          handleRadioGroupChange={handleRadioGroupChange}
          handleTradeProfileNavButton={handleTradeProfileNavButton}
          questionFormData={questionFormData.risk}
          setFormProgress={setFormProgress}
          name="riskPerTrade"
          id="risk-per-trade"
          value={inputFields.riskPerTrade}
        />
      );
    else if (formProgress.page === 2)
      return (
        <QuestionForm
          inputFields={inputFields}
          formProgress={formProgress}
          handleRadioGroupChange={handleRadioGroupChange}
          handleTradeProfileNavButton={handleTradeProfileNavButton}
          questionFormData={questionFormData.pairPreference}
          setFormProgress={setFormProgress}
          name="pairingPreference"
          id="pairing-preference"
          value={inputFields.pairingPreference}
        />
      );
    else if (formProgress.page >= 3)
      return (
        <QuestionForm
          inputFields={inputFields}
          formProgress={formProgress}
          handleRadioGroupChange={handleRadioGroupChange}
          handleTradeProfileNavButton={handleTradeProfileNavButton}
          questionFormData={questionFormData.rateOfReturn}
          setFormProgress={setFormProgress}
          name="rateOfReturn"
          id="rate-of-return"
          value={inputFields.rateOfReturn}
        />
      );
  };
  return (
    <section className="trade-profile">
      <form className="trade-profile__layout">
        {renderContent(formProgress)}
      </form>
    </section>
  );
};

export default TradeProfile;
