import React from 'react';
import { ResponsivePie } from '@nivo/pie';

import './CoinAllocationChart.scss';

const CoinAllocationChart = ({ data }) => (
  <div className="coin-allocation-chart">
    <ResponsivePie
      data={data}
      margin={{ top: 20, right: 20, bottom: 20, left: 20 }}
      innerRadius={0.5}
      padAngle={0.7}
      cornerRadius={3}
      colors={{ scheme: 'paired' }}
      borderWidth={1}
      borderColor={{ from: 'color', modifiers: [['darker', 0.2]] }}
      radialLabelsSkipAngle={10}
      radialLabelsTextXOffset={10}
      radialLabelsTextColor="#121212"
      radialLabelsLinkOffset={0}
      radialLabelsLinkDiagonalLength={2}
      radialLabelsLinkHorizontalLength={0}
      radialLabelsLinkStrokeWidth={1}
      radialLabelsLinkColor={{ from: 'color' }}
      slicesLabelsSkipAngle={10}
      slicesLabelsTextColor="#121212"
      animate={true}
      motionStiffness={90}
      motionDamping={15}
      enableSlicesLabels={false}
      sortByValue={true}
      defs={[
        {
          id: 'dots',
          type: 'patternDots',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: 'lines',
          type: 'patternLines',
          background: 'inherit',
          color: 'rgba(255, 255, 255, 0.3)',
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      fill={[
        {
          match: {
            id: 'BTC',
          },
          id: 'dots',
        },
        {
          match: {
            id: 'XMR',
          },
          id: 'lines',
        },
      ]}
      // legends={[
      //   {
      //     anchor: 'bottom',
      //     direction: 'row',
      //     translateY: 56,
      //     itemWidth: 70,
      //     itemHeight: 18,
      //     itemTextColor: '#999',
      //     symbolSize: 12,
      //     symbolShape: 'circle',
      //     effects: [
      //       {
      //         on: 'hover',
      //         style: {
      //           itemTextColor: '#000',
      //         },
      //       },
      //     ],
      //   },
      // ]}
    />
  </div>
);

export default CoinAllocationChart;
