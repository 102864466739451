import React from 'react';
import { connect } from 'react-redux';

import { ReactComponent as CloseIcon } from '../../../static/svg/close-icon.svg';
import { ReactComponent as CancelSignalIcon } from '../../../static/svg/cancel-signal-icon.svg';
import { openSnackbar } from '../../../redux/Snackbar/snackbar.actions';

import './SignalModal.scss';

const DataGroup = ({ label, data, labelColorClass }) => (
  <div className="data-group">
    <span className={`data-group__label ${labelColorClass}`}>{label}</span>
    <span className="data-group__data">{data}</span>
  </div>
);

const SignalModal = ({
  signal,
  handleToggleSignalModal,
  role,
  openSnackbar,
}) => {
  return (
    <div className="signal-modal">
      <CloseIcon
        aria-label="close"
        onClick={handleToggleSignalModal}
        className="signal-modal__close-btn"
      />
      <div className="signal-modal__content">
        <div className="signal-modal__row">
          <DataGroup
            label="coid"
            data={signal.coid}
            labelColorClass="data-group__label--blue"
          />
        </div>
        <div className="signal-modal__row">
          <DataGroup
            label="symbol"
            data={signal.symbol}
            labelColorClass="data-group__label--yellow"
          />
          <DataGroup
            label="side"
            data={signal.side}
            labelColorClass="data-group__label--yellow"
          />
          <DataGroup
            label="type"
            data={signal.type}
            labelColorClass="data-group__label--yellow"
          />
        </div>
        <div className="signal-modal__row">
          <DataGroup
            label="amount"
            data={signal.amount}
            labelColorClass="data-group__label--violet"
          />
          <DataGroup
            label="time in force"
            data={signal.timeInForce}
            labelColorClass="data-group__label--violet"
          />
          <DataGroup
            label="stop price"
            data={signal.stopPrice}
            labelColorClass="data-group__label--violet"
          />
        </div>
        <div className="signal-modal__row">
          <DataGroup
            label="date"
            data={signal.date}
            labelColorClass="data-group__label--green"
          />
          <DataGroup
            label="status"
            data={signal.status}
            labelColorClass="data-group__label--green"
          />
          <DataGroup
            label="profit"
            data={signal.profit}
            labelColorClass="data-group__label--green"
          />
        </div>
        {role === 'signaler' || role === 'superAdmin' ? (
          <div className="signal-modal__row">
            <DataGroup
              label="user"
              data={signal.user}
              labelColorClass="data-group__label--red"
            />
            {role === 'superAdmin' ? (
              <DataGroup
                label="signaler"
                data={signal.signaler}
                labelColorClass="data-group__label--red"
              />
            ) : null}
          </div>
        ) : null}
        {role === 'signaler' || role === 'superAdmin' ? (
          <div className="signal-modal__cancel-signal">
            <span>Cancel Signal</span>
            <CancelSignalIcon
              onClick={() => {
                handleToggleSignalModal();
                openSnackbar('Signal Canceled');
              }}
              aria-label="cancel signal"
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  openSnackbar: message => dispatch(openSnackbar(message)),
});

export default connect(
  null,
  mapDispatchToProps,
)(SignalModal);
