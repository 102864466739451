import React, { useState } from 'react';
import Switch from '@material-ui/core/Switch';

import BinanceIcon from '../../../static/svg/binance-logo-small.svg';
import ProfileIcon from '../../../static/svg/profile-icon-sm.svg';

import './AccountStatus.scss';

const AccountStatus = ({
  binanceIsLinked,
  profileIsSet,
  accountIsActive,
  role,
}) => {
  const [accountSwitchValue, setAccountSwitchValue] = useState(accountIsActive);

  const handleAccountSwitch = () =>
    setAccountSwitchValue(prevState => !prevState);

  const accountIsComplete = binanceIsLinked && profileIsSet;

  const statusCompleteClass =
    accountIsComplete && accountSwitchValue ? 'account-status--complete' : '';

  let statusMsg;

  if (accountSwitchValue && accountIsComplete) statusMsg = 'account active';
  else if (!accountIsComplete) statusMsg = 'account incomplete';
  else if (!accountSwitchValue && accountIsComplete)
    statusMsg = 'account deactivated';

  const statusSubText = accountIsComplete
    ? 'Turn your account on or off at any time.'
    : 'Finish setting up your account to activate it!';
  return (
    <>
      {role === 'user' ? (
        <div className={`account-status ${statusCompleteClass}`}>
          <div className="account-status__msg-container ">
            <span className="account-status__msg">{statusMsg}</span>
            <span className="account-status__sub-text">
              {statusSubText}{' '}
              <Switch
                checked={accountSwitchValue}
                onChange={handleAccountSwitch}
                value="account-active-switch"
                inputProps={{
                  'aria-label': 'active-deactivate account switch',
                }}
                disabled={!accountIsComplete}
              />
            </span>
          </div>
          {accountIsComplete ? null : (
            <div className="account-status__todos">
              {binanceIsLinked ? null : (
                <div className="account-status__todo-item account-status__todo-item--binance">
                  <img
                    src={BinanceIcon}
                    alt="binance logo"
                    className="account-status__todo-icon "
                  />
                  Link your binance account
                </div>
              )}
              {profileIsSet ? null : (
                <div className="account-status__todo-item">
                  <img
                    src={ProfileIcon}
                    alt="binance logo"
                    className="account-status__todo-icon account-status__todo-icon--profile"
                  />
                  Set up your trade profile
                </div>
              )}
            </div>
          )}
        </div>
      ) : null}
    </>
  );
};

export default AccountStatus;
