import React from 'react';
import { Link } from 'react-router-dom';

import PageTitle from '../../components/PageTitle';
import RegisterCTA from '../../components/RegisterCTA';

import '../../scss/typography.scss';
import './AboutUs.scss';

const AboutUs = () => (
  <main className="about-us">
    <PageTitle title="about us" />
    <section className="about-us__content">
      <h2 className="sub-section-headline">Lorem Ipsum Dolor</h2>
      <p className="body-text">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
        tempor incididunt ut labore et dolore magna aliqua. Urna porttitor
        rhoncus dolor purus non enim. Congue eu consequat ac felis donec et odio
        pellentesque. Gravida neque convallis a cras semper auctor neque vitae
        tempus. Morbi leo urna molestie at elementum eu facilisis sed odio.
        Dignissim enim sit amet venenatis urna cursus eget nunc. Gravida dictum
        fusce ut placerat orci nulla pellentesque. Odio aenean sed adipiscing
        diam. Tempor orci eu lobortis elementum nibh. Porta non pulvinar neque
        laoreet suspendisse interdum consectetur libero id. Metus vulputate eu
        scelerisque felis imperdiet proin fermentum leo vel. Euismod quis
        viverra nibh cras pulvinar mattis nunc. Proin nibh nisl condimentum id
        venenatis a condimentum vitae sapien.
      </p>
      <p className="body-text">
        Et netus et malesuada fames ac turpis. Elementum eu facilisis sed odio.
        Viverra nam libero justo laoreet sit amet. Magna ac placerat vestibulum
        lectus mauris ultrices eros in cursus. A lacus vestibulum sed arcu non
        odio euismod lacinia at. Semper feugiat nibh sed pulvinar proin gravida
        hendrerit lectus a. Massa massa ultricies mi quis hendrerit dolor magna.
        Augue eget arcu dictum varius duis at consectetur lorem. Euismod quis
        viverra nibh cras pulvinar mattis. Amet dictum sit amet justo. Ornare
        suspendisse sed nisi lacus sed.
      </p>

      <div className="about-us__feature about-us__feature--computer-bg">
        <span className="about-us__feature-title">
          Easy to use, free to start
        </span>
      </div>
      <div className="about-us__feature about-us__feature--lock-bg">
        <span className="about-us__feature-title">
          Secure trades, made on your behalf
        </span>
      </div>
      <p className="body-text">
        Go to our{` `}
        <Link to="/guide">guide</Link> to learn about how to set up your trading
        profile, connect your binance account, and more.
      </p>
    </section>
    <RegisterCTA />
  </main>
);

export default AboutUs;
