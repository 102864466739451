import React from 'react';
import PropTypes from 'prop-types';

const ActivityNotificationsIcon = ({
  color = '979797',
  classes = 'dashboard-menu__link-icon',
}) => (
  <svg
    width="18"
    height="26"
    viewBox="0 0 18 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.27586 2.68966V1.79123C6.27586 0.805337 7.08062 0 8.07334 0H8.96114C9.95719 0 10.7586 0.801962 10.7586 1.79123V2.68966H12.5557C13.0495 2.68966 13.4483 3.09064 13.4483 3.58527V4.48369C13.4483 4.98244 13.0487 5.37931 12.5557 5.37931H4.47879C3.98503 5.37931 3.58621 4.97833 3.58621 4.48369V3.58527C3.58621 3.08652 3.98583 2.68966 4.47879 2.68966H6.27586ZM14.3448 3.58621H15.2437C16.2357 3.58621 17.0345 4.39045 17.0345 5.38253V24.2037C17.0345 25.2023 16.2327 26 15.2437 26H1.79079C0.798761 26 0 25.1958 0 24.2037V5.38253C0 4.38393 0.801765 3.58621 1.79079 3.58621H2.68966C2.68966 3.58767 2.68966 3.58912 2.68966 3.59058V4.47838C2.68966 5.4711 3.49076 6.27586 4.48523 6.27586H12.5492C13.5409 6.27586 14.3448 5.47443 14.3448 4.47838V3.59058C14.3448 3.58912 14.3448 3.58767 14.3448 3.58621ZM8.51724 2.68966C8.76482 2.68966 8.96552 2.48896 8.96552 2.24138C8.96552 1.9938 8.76482 1.7931 8.51724 1.7931C8.26967 1.7931 8.06897 1.9938 8.06897 2.24138C8.06897 2.48896 8.26967 2.68966 8.51724 2.68966ZM2.68966 9.86207V10.7586H14.3448V9.86207H2.68966ZM2.68966 12.5517V13.4483H14.3448V12.5517H2.68966ZM2.68966 15.2414V16.1379H14.3448V15.2414H2.68966ZM2.68966 17.931V18.8276H14.3448V17.931H2.68966ZM2.68966 20.6207V21.5172H14.3448V20.6207H2.68966Z"
      fill={`#${color}`}
    />
  </svg>
);

ActivityNotificationsIcon.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.string,
};

export default ActivityNotificationsIcon;
