import React from 'react';

import DashComponent from '../../components/Dashboard/DashComponent';
import TableDisplay from '../../components/Dashboard/TableDisplay';

import { ReactComponent as SignalsIcon } from '../../static/svg/signals-icon.svg';

import './DashSignalHistory.scss';

import signalList from '../../components/Dashboard/TableDisplay/seedData';

const DashSignalHistory = ({
  handleToggleSignalModal,
  signalModalIsOpen,
  role,
}) => {
  document.body.style.overflow = signalModalIsOpen ? 'hidden' : 'auto';

  return (
    <section className="dash-signal-history">
      <DashComponent role={role} svg={<SignalsIcon />} title="signal history">
        <TableDisplay
          role={role}
          handleToggleModal={handleToggleSignalModal}
          numPages={5}
          headerTitleArray={['symbol', 'amount', 'date', 'status', 'profit']}
          removeHeaderAtSmallArray={['date', 'status']}
          tableRowsData={signalList}
          type="signal history"
          paginationPath="/dashboard/signal-history"
        />
      </DashComponent>
    </section>
  );
};

export default DashSignalHistory;
