import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

import Button from '../../../components/Button';

import './LowerCTA.scss';

const LowerCTA = () => {
  const [isCTAEntered, setIsCTAEntered] = useState(false);

  const handleEnterCTA = () => setIsCTAEntered(true);

  const revealCTAClass = isCTAEntered ? 'reveal' : '';

  return (
    <Waypoint onEnter={handleEnterCTA} bottomOffset="35%">
      <section className={`lower-cta ${revealCTAClass}`}>
        <h2 className="section-headline section-headline--white">
          We Will Battle For Your Gains
        </h2>
        <p className="lower-cta__description">
          We are available 24/7 through our discord server or contact form.
        </p>
        <Button header={false} type="solid" element="link" href="/contact-us">
          Contact Us
        </Button>
      </section>
    </Waypoint>
  );
};

export default LowerCTA;
