import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunk from 'redux-thunk';
import logger from 'redux-logger';
import rootReducer from './rootReducer';

const middleWares = [thunk, logger];

const configureStore = () =>
  createStore(
    rootReducer,
    composeWithDevTools(applyMiddleware(...middleWares)),
  );

export default configureStore;
