import React from 'react';
import PropTypes from 'prop-types';

const SettingsIcon = ({
  color = '979797',
  classes = 'dashboard-menu__link-icon',
}) => (
  <svg
    className={classes}
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.1217 7.62652C12.6195 5.60516 12.079 3.38045 10.4996 1.80118C8.76946 0.0710334 6.26495 -0.412498 4.10169 0.349812L7.84332 4.09144C8.35957 4.60782 8.35957 5.44492 7.84332 5.96142L7.03341 6.77133C6.98736 6.81238 6.94002 6.85151 6.89588 6.89565C6.85175 6.93978 6.81275 6.98699 6.77183 7.03292L5.96166 7.84308C5.44529 8.35946 4.60793 8.35946 4.09168 7.84308L0.349922 4.10145C-0.412516 6.26483 0.0708874 8.76922 1.80116 10.4995C3.38056 12.0789 5.6054 12.6195 7.62676 12.1217L20.5742 25.0691C21.8155 26.3103 23.8278 26.3103 25.0692 25.0691C26.3105 23.8277 26.3105 21.8152 25.0692 20.574L12.1217 7.62652ZM23.6735 23.6734C23.1021 24.2449 22.1753 24.2449 21.6038 23.6735C21.0325 23.1021 21.0325 22.1753 21.604 21.6039C22.1756 21.0322 23.1021 21.0324 23.6736 21.6038C24.245 22.1752 24.245 23.1017 23.6735 23.6734Z"
      fill={`#${color}`}
    />
    <path
      d="M14.9073 9.11279L21.9357 2.08458C22.1067 1.91344 22.3839 1.91332 22.5552 2.08446C22.7262 2.2556 22.7262 2.53322 22.5552 2.70423L15.527 9.73244L16.5407 10.7462L23.5688 3.718C23.7401 3.54685 24.0176 3.54698 24.1886 3.718C24.3597 3.88914 24.3597 4.16651 24.1886 4.33765L17.1604 11.3659V11.366L18.2814 12.487L25.1174 5.65097C26.3587 4.40975 26.3587 2.39723 25.1174 1.15588C23.8761 -0.0853432 21.8636 -0.0854714 20.6223 1.15588L13.7863 7.99191L14.9073 9.11279Z"
      fill={`#${color}`}
    />
    <path
      d="M9.23888 15.2255L5.08517 19.5354L3.0139 20.0904L0.228302 24.401L1.75369 25.9263L6.06417 23.1409L6.61929 21.0697L10.929 16.9157L9.23888 15.2255Z"
      fill={`#${color}`}
    />
  </svg>
);

SettingsIcon.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.string,
};

export default SettingsIcon;
