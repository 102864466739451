import React from 'react';

import AuthForm from './AuthForm';

import './Auth.scss';

const Login = () => (
  <main className="auth">
    <AuthForm />
  </main>
);

export default Login;
