import React from 'react';
import { Link } from 'react-router-dom';

import Button from '../../../../components/Button';

import './BeginForm.scss';

const BeginForm = ({ handleBeginClick, formProgress }) => {
  const renderButtonText = formProgress => {
    if (formProgress.profileComplete === true) return 'Update Answers';
    else if (formProgress.questionsAnswered === 0) return 'Begin';
    else return 'Continue';
  };
  return (
    <>
      <p className="body-text">
        The trade profiler is a series of questions designed to help assess your
        goals and help us trade within that context. For more information or
        tips on how to accurately generate a trading profile, check out the{' '}
        <Link to="/guide#set-up-your-trading-profile">GUIDE</Link>.
      </p>
      <Button
        onClick={handleBeginClick}
        header={false}
        element="button"
        type="solid"
      >
        {renderButtonText(formProgress)}
      </Button>
    </>
  );
};

export default BeginForm;
