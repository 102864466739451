import React, { useState } from 'react';
import { Avatar } from '@material-ui/core';

import SelectInput from '../../../components/SelectInput';

import AvatarImg from '../../../static/img/avatar-2.jpg';
import AvatarImg2 from '../../../static/img/avatar-3.jpg';
import AvatarImg3 from '../../../static/img/avatar-4.jpg';

import './FilterForm.scss';

const signalerArray = ['signalsender12', 'kingofcoin', 'ninjatrader'];

const specificDates = {
  today: ['Jul 19, 2019'],
  month: [
    `Jan 2019`,
    `Feb 2019`,
    `Mar 2019`,
    `Apr 2019`,
    `May 2019`,
    `Jun 2019`,
    `Jul 2019`,
  ],
  year: ['2019'],
  overall: ['2019 - 2020'],
};

const FilterForm = ({ role }) => {
  const [inputField, setInputField] = useState({
    timeFrame: 'month',
    signaler: 'signalsender12',
    date: 'Jul 2019',
  });

  const { timeFrame, signaler, date } = inputField;

  const handleSelectChange = e => {
    e.persist();

    if (e.target.name === 'timeFrame') {
      setInputField(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value,
        date: specificDates[e.target.value][0],
      }));
    } else
      setInputField(prevState => ({
        ...prevState,
        [e.target.name]: e.target.value,
      }));

    // Send off async request using inputField value
  };

  const getItems = () => {
    if (timeFrame === 'today') return specificDates.today;
    else if (timeFrame === 'month') return specificDates.month;
    else if (timeFrame === 'year') return specificDates.year;
    else if (timeFrame === 'overall') return specificDates.overall;
  };

  const getAvatarImg = () => {
    if (signaler === 'signalsender12') return AvatarImg;
    else if (signaler === 'kingofcoin') return AvatarImg2;
    else return AvatarImg3;
  };

  const renderSuperAdminFilters = () =>
    role === 'superAdmin' ? (
      <div className="filter-form__signaler-select-group">
        <div className="filter-form__avatar-group">
          <div className="filter-form__avatar-status" />
          <Avatar
            className="filter-form__avatar"
            src={getAvatarImg()}
            alt="Gravatar image"
          />
        </div>

        <SelectInput
          onChange={handleSelectChange}
          value={signaler}
          id="signaler"
          name="signaler"
          menuItemArray={signalerArray}
          label="signaler"
        />
      </div>
    ) : null;

  const displayFlexClass = role === 'superAdmin' ? 'filter-form--flex' : '';

  return (
    <div className={`filter-form ${displayFlexClass}`}>
      {renderSuperAdminFilters()}
      <div className="filter-form__date-select-group">
        <SelectInput
          onChange={handleSelectChange}
          value={timeFrame}
          id="time-frame"
          name="timeFrame"
          menuItemArray={['today', 'month', 'year', 'overall']}
          label="Filter By"
        />
        {role !== 'superAdmin' ? (
          <SelectInput
            onChange={handleSelectChange}
            value={date}
            id="date"
            name="date"
            menuItemArray={getItems()}
            label="Date"
            disabled={timeFrame === 'today' || timeFrame === 'overall'}
          />
        ) : null}
      </div>
      {role === 'superAdmin' ? (
        <SelectInput
          onChange={handleSelectChange}
          value={date}
          id="date"
          name="date"
          menuItemArray={getItems()}
          label="Date"
          disabled={timeFrame === 'today' || timeFrame === 'overall'}
        />
      ) : null}
    </div>
  );
};

export default FilterForm;
