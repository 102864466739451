import React, { useReducer } from 'react';
import {
  List,
  ListItem,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  Divider,
  Switch,
} from '@material-ui/core';

import { Link } from 'react-router-dom';

import EmailIcon from '../../../static/svg/email-icon.svg';
import DiscordIcon from '../../../static/svg/discord-icon.svg';
import TelegramIcon from '../../../static/svg/telegram-icon.svg';

import './Notifications.scss';

const types = {
  NOTIFY_BY_EMAIL: 'NOTIFY_BY_EMAIL',
  NOTIFY_BY_DISCORD: 'NOTIFY_BY_DISCORD',
  NOTIFY_BY_TELEGRAM: 'NOTIFY_BY_TELEGRAM',
};

const Notifications = ({ notificationPreferences }) => {
  console.log(notificationPreferences);
  const [state, dispatch] = useReducer(reducer, notificationPreferences);

  function reducer(state, action) {
    switch (action.type) {
      case types.NOTIFY_BY_EMAIL:
        return {
          ...state,
          email: { ...state.email, active: action.payload },
        };
      case types.NOTIFY_BY_DISCORD:
        return {
          ...state,
          discord: { ...state.discord, active: action.payload },
        };
      case types.NOTIFY_BY_TELEGRAM:
        return {
          ...state,
          telegram: { ...state.telegram, active: action.payload },
        };

      default:
        return { ...state };
    }
  }

  const emailActiveClass = state.email.active ? 'active--email' : '';
  const discordActiveClass = state.discord.active ? 'active--discord' : '';
  const telegramActiveClass = state.telegram.active ? 'active--telegram' : '';
  const emailNotConnectedClass = state.email.linked ? '' : 'not-connected';
  const discordNotConnectedClass = state.discord.linked ? '' : 'not-connected';
  const telegramNotConnectedClass = state.telegram.linked
    ? ''
    : 'not-connected';

  return (
    <div className="notifications">
      <p className="body-text">
        Visit the <Link to="/guide">guide</Link> for more info on how to link
        your account to email, discord or telegram.
      </p>
      <List>
        <ListItem>
          <ListItemIcon className={emailNotConnectedClass}>
            <img src={EmailIcon} alt="email icon" />
          </ListItemIcon>
          <ListItemText
            className={`${emailActiveClass} ${emailNotConnectedClass}`}
            id="switch-list-label-email"
            primary={state.email.linked ? 'email' : 'not connected'}
          />
          <ListItemSecondaryAction>
            <Switch
              className="switch--email"
              edge="end"
              inputProps={{ 'aria-labelledby': 'switch-list-label-email' }}
              onChange={() =>
                dispatch({
                  type: types.NOTIFY_BY_EMAIL,
                  payload: !state.email.active,
                })
              }
              checked={state.email.active}
              name="email"
              disabled={!state.email.linked}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon className={discordNotConnectedClass}>
            <img src={DiscordIcon} alt="discord logo" />
          </ListItemIcon>
          <ListItemText
            className={`${discordActiveClass} ${discordNotConnectedClass}`}
            id="switch-list-label-discord"
            primary={state.discord.linked ? 'discord' : 'not connected'}
          />
          <ListItemSecondaryAction>
            <Switch
              className="switch--discord"
              edge="end"
              inputProps={{ 'aria-labelledby': 'switch-list-label-discord' }}
              onChange={() =>
                dispatch({
                  type: types.NOTIFY_BY_DISCORD,
                  payload: !state.discord.active,
                })
              }
              checked={state.discord.active}
              name="discord"
              disabled={!state.discord.linked}
            />
          </ListItemSecondaryAction>
        </ListItem>
        <Divider />
        <ListItem>
          <ListItemIcon className={telegramNotConnectedClass}>
            <img src={TelegramIcon} alt="telegram logo" />
          </ListItemIcon>
          <ListItemText
            className={`${telegramActiveClass} ${telegramNotConnectedClass}`}
            id="switch-list-label-telegram"
            primary={state.telegram.linked ? 'telegram' : 'not connected'}
          />
          <ListItemSecondaryAction>
            <Switch
              className="switch--telegram"
              edge="end"
              inputProps={{ 'aria-labelledby': 'switch-list-label-telegram' }}
              onChange={() =>
                dispatch({
                  type: types.NOTIFY_BY_TELEGRAM,
                  payload: !state.telegram.active,
                })
              }
              checked={state.telegram.active}
              name="telegram"
              disabled={!state.telegram.linked}
            />
          </ListItemSecondaryAction>
        </ListItem>
      </List>
    </div>
  );
};

export default Notifications;
