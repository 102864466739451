import React from 'react';
import PropTypes from 'prop-types';

const LogOutIcon = ({
  color = '979797',
  classes = 'dashboard-menu__link-icon',
}) => (
  <svg
    className={classes}
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.97318 8.02635L5.97694 10.0226L8.76377 12.8099H0V15.6334H8.76377L5.97694 18.4207L7.97318 20.4169L14.168 14.2216L7.97318 8.02635ZM9.59906 0V6.09318L12.2353 8.72941V2.63529H23.7412L16.6122 7.38023V23.3647H12.2348V19.7139L9.59859 22.3501V26H27.0381V0H9.59906Z"
      fill={`#${color}`}
    />
  </svg>
);

LogOutIcon.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.string,
};

export default LogOutIcon;
