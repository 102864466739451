import React from 'react';
import PropTypes from 'prop-types';

import './MenuIcon.scss';

const MenuIcon = ({ isMenuOpen, handleMenuToggle, type }) => {
  const menuOpenClass = isMenuOpen && type !== 'dashboard' ? 'menu-open' : '';
  return (
    <svg
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="menu-icon"
      onClick={handleMenuToggle}
    >
      <path
        className={`menu-icon__top-bar ${menuOpenClass}`}
        d="M26.6 7.0002H1.4C0.56 7.0002 0 6.4402 0 5.6002C0 4.7602 0.56 4.2002 1.4 4.2002H26.6C27.44 4.2002 28 4.7602 28 5.6002C28 6.4402 27.44 7.0002 26.6 7.0002Z"
        fill="#EFEFEF"
      />
      <path
        className={`menu-icon__mid-bar ${menuOpenClass}`}
        d="M14 15.4001H1.4C0.56 15.4001 0 14.8401 0 14.0001C0 13.1601 0.56 12.6001 1.4 12.6001H14C14.84 12.6001 15.4 13.1601 15.4 14.0001C15.4 14.8401 14.84 15.4001 14 15.4001Z"
        fill="#EFEFEF"
      />
      <path
        className={`menu-icon__bot-bar ${menuOpenClass}`}
        d="M26.6 23.8H1.4C0.56 23.8 0 23.24 0 22.4C0 21.56 0.56 21 1.4 21H26.6C27.44 21 28 21.56 28 22.4C28 23.24 27.44 23.8 26.6 23.8Z"
        fill="#EFEFEF"
      />
    </svg>
  );
};

MenuIcon.propTypes = {
  isMenuOpen: PropTypes.bool.isRequired,
  handleMenuToggle: PropTypes.func.isRequired,
};

export default MenuIcon;
