import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

import LaptopPromo from '../../../static/img/laptop-promo-high.png';
import MobilePromo from '../../../static/img/mobile-promo-high.png';

import './Promo.scss';

const Promo = () => {
  const [isLaptopPromoEntered, setIsLaptopPromoEntered] = useState(false);
  const [isMobilePromoEntered, setIsMobilePromoEntered] = useState(false);

  const handleEnterLaptopPromo = () => setIsLaptopPromoEntered(true);
  const handleEnterMobilePromo = () => setIsMobilePromoEntered(true);

  const revealLaptopPromoClass = isLaptopPromoEntered ? 'reveal' : '';
  const revealMobilePromoClass = isMobilePromoEntered ? 'reveal' : '';

  return (
    <section className="promo">
      <Waypoint onEnter={handleEnterLaptopPromo} bottomOffset="60%">
        <div className={`promo__laptop ${revealLaptopPromoClass}`}>
          <img
            className="promo__img promo__img--laptop"
            src={LaptopPromo}
            alt="Laptop promo showing the user dashboard's signal details screen."
          />
          <div
            className={`promo__content promo__content--laptop ${revealLaptopPromoClass}`}
          >
            <h2 className="section-headline">The NINJA Advantage</h2>
            <p className="promo__description">
              Using the most advanced markers and strategies, we can win profits
              while you go about your day. Never spend another day sitting in
              front of a device, watching the market. Instead, you can spend
              more time doing what you love.
            </p>
          </div>
        </div>
      </Waypoint>
      <Waypoint onEnter={handleEnterMobilePromo} bottomOffset="60%">
        <div className={`promo__mobile ${revealMobilePromoClass}`}>
          <div
            className={`promo__content promo__content--mobile ${revealMobilePromoClass}`}
          >
            <h2 className="section-headline">Access From ANYWHERE</h2>
            <p className="promo__description">
              Our user dashboard is fully responsive. You can update your
              settings, edit your trader profile, view your statistics and more
              on any device that can connect to the internet via browser.
            </p>
          </div>
          <img
            className="promo__img promo__img--mobile"
            src={MobilePromo}
            alt="Mobile promo showing the user dashboard's home screen."
          />
        </div>
      </Waypoint>
    </section>
  );
};

export default Promo;
