import { MessageModalTypes } from './message-modal.types';

const INITIAL_STATE = {
  isOpen: false,
};

const messageModalReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MessageModalTypes.OPEN_MESSAGE_MODAL:
      return { ...state, isOpen: !state.isOpen };
    default:
      return state;
  }
};

export default messageModalReducer;
