import React from 'react';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import RadioButtonOutline from '@material-ui/icons/RadioButtonUncheckedOutlined';
import RadioButtonIcon from '@material-ui/icons/RadioButtonChecked';

import Button from '../../../../components/Button';

import { ReactComponent as NinjaSwordLeft } from '../../../../static/svg/ninja-sword-left.svg';
import { ReactComponent as NinjaSwordRight } from '../../../../static/svg/ninja-sword-right.svg';

import './QuestionForm.scss';

const QuestionForm = ({
  handleRadioGroupChange,
  handleTradeProfileNavButton,
  formProgress,
  questionFormData,
  name,
  id,
  value,
}) => {
  const progressClassOne = formProgress.page > 0 ? 'fill' : '';
  const progressClassTwo = formProgress.page > 1 ? 'fill' : '';
  const progressClassThree = formProgress.page > 2 ? 'fill' : '';
  const nextBtnDisabledClass = formProgress.page > 3 ? 'disabled' : '';

  return (
    <>
      {formProgress.page === 4 ? (
        <div className="trade-profile__submit-form">
          <p className="body-text">
            Ready to start winning? Submit your answers to take the next step
            toward activating your account. Once you submit your trade profile
            and link your Binance credentials, you can activate your account!
          </p>
          <Button
            disabled={formProgress.questionsAnswered < 3}
            type="solid"
            header={false}
            element="input"
            value="Submit"
          />
        </div>
      ) : (
        <>
          <p className="body-text">{questionFormData.question}</p>
          <FormControl component="fieldset">
            <RadioGroup
              id={id}
              aria-label="risk-per-trade"
              name={name}
              value={value}
              onChange={handleRadioGroupChange}
              row
            >
              <FormControlLabel
                value={questionFormData.answers[0]}
                control={
                  <Radio
                    icon={<RadioButtonOutline fontSize="large" />}
                    checkedIcon={<RadioButtonIcon fontSize="large" />}
                  />
                }
                label={questionFormData.answers[0]}
                labelPlacement="bottom"
              />
              <FormControlLabel
                value={questionFormData.answers[1]}
                control={
                  <Radio
                    icon={<RadioButtonOutline fontSize="large" />}
                    checkedIcon={<RadioButtonIcon fontSize="large" />}
                  />
                }
                label={questionFormData.answers[1]}
                labelPlacement="bottom"
              />

              <FormControlLabel
                value={questionFormData.answers[2]}
                control={
                  <Radio
                    icon={<RadioButtonOutline fontSize="large" />}
                    checkedIcon={<RadioButtonIcon fontSize="large" />}
                  />
                }
                label={questionFormData.answers[2]}
                labelPlacement="bottom"
              />
            </RadioGroup>
          </FormControl>
        </>
      )}

      <div className="trade-profile__progress-display">
        <div className="trade-profile__button-group">
          <span>prev</span>
          <NinjaSwordLeft
            tabIndex={0}
            aria-label="previous"
            data-direction="previous"
            className={`trade-profile__nav-button sword--left`}
            onClick={handleTradeProfileNavButton}
          />
        </div>
        <svg
          width="122"
          height="14"
          viewBox="0 0 122 14"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="trade-profile__progress-bar"
        >
          <path
            d="M33.0833 13H1L14.75 1H46.8333L33.0833 13Z"
            stroke="#f3ad61"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`trade-profile__progress-icon ${progressClassOne}`}
          />
          <path
            d="M70.0833 13H38L51.75 1H83.8333L70.0833 13Z"
            stroke="#f3ad61"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`trade-profile__progress-icon ${progressClassTwo}`}
          />
          <path
            d="M107.083 13H75L88.75 1H120.833L107.083 13Z"
            stroke="#f3ad61"
            strokeLinecap="round"
            strokeLinejoin="round"
            className={`trade-profile__progress-icon ${progressClassThree}`}
          />
        </svg>
        <div className={`trade-profile__button-group ${nextBtnDisabledClass}`}>
          <span>next</span>
          <NinjaSwordRight
            tabIndex={0}
            aria-label="next"
            data-direction="next"
            className="trade-profile__nav-button sword--right"
            onClick={handleTradeProfileNavButton}
          />
        </div>
      </div>
    </>
  );
};

export default QuestionForm;
