import React, { useState, useEffect } from 'react';
import { withRouter, Switch, Route } from 'react-router-dom';
import { connect } from 'react-redux';

import Snackbar from '../Snackbar';
import DashboardMenu from './DashboardMenu';
import DashHome from '../../scenes/DashHome';
import DashTradeInterface from '../../scenes/DashTradeInterface';
import DashSignalHistory from '../../scenes/DashSignalHistory';
import DashActivity from '../../scenes/DashActivity';
import DashSettings from '../../scenes/DashSettings';
import SignalModal from './SignalModal';
import MessageModal from './MessageModal';
import { closeSnackbar } from '../../redux/Snackbar/snackbar.actions';

import UserAvatar from '../../static/img/avatar-4.jpg';

import './Dashboard.scss';

const signalData = {
  coid: '6gCrw2kRUAF9CvJDGP16IP',
  symbol: 'ethbtc',
  side: 'buy',
  type: 'stop loss',
  amount: '0.2',
  timeInForce: 'GTC',
  stopPrice: '0.023',
  date: '06/11/2019',
  status: 'filled',
  profit: '+$100',
  user: 'user223847',
  signaler: 'signaler88117',
};

const userMessageData = {
  avatar: {
    src: UserAvatar,
  },
  username: 'udownwithbtc',
  notifications: {
    email: {
      isPreferred: true,
      address: 'test@gmail.com',
    },
    discord: {
      isPreferred: false,
    },

    telegram: {
      isPreferred: true,
    },
  },
};

const Dashboard = ({
  location,
  role,
  dashMenuIsOpen,
  messageModalIsOpen,
  snackbarIsOpen,
  closeSnackbar,
  snackbarMessage,
}) => {
  const [route, setRoute] = useState('');
  const [signalModalIsOpen, setSignalModalIsOpen] = useState(false);

  const handleToggleSignalModal = () =>
    setSignalModalIsOpen(prevState => !prevState);

  const menuOpenClass =
    dashMenuIsOpen || signalModalIsOpen || messageModalIsOpen
      ? 'menu-open'
      : '';

  useEffect(() => {
    const splitPathnameArray = location.pathname.split('/');
    const lastNameInPath =
      splitPathnameArray.length === 2
        ? splitPathnameArray[1]
        : splitPathnameArray[2];

    const handleClickOnBackground = e => {
      const { classList } = e.target;

      if (classList.contains('dashboard') && signalModalIsOpen)
        handleToggleSignalModal();
    };

    setRoute(lastNameInPath);

    window.addEventListener('click', handleClickOnBackground);

    return () => {
      window.removeEventListener('click', handleClickOnBackground);
    };
  }, [location, signalModalIsOpen]);

  document.body.style.overflow =
    dashMenuIsOpen || signalModalIsOpen || messageModalIsOpen
      ? 'hidden'
      : 'auto';

  document.documentElement.style.overflowY =
    dashMenuIsOpen || signalModalIsOpen || messageModalIsOpen
      ? 'hidden'
      : 'auto';

  return (
    <main className={`dashboard ${menuOpenClass}`}>
      <Snackbar
        open={snackbarIsOpen}
        onClose={closeSnackbar}
        message={snackbarMessage}
      />
      {signalModalIsOpen ? (
        <SignalModal
          signal={signalData}
          handleToggleSignalModal={handleToggleSignalModal}
          role={role}
        />
      ) : null}

      {messageModalIsOpen ? (
        <MessageModal
          user={userMessageData}
          messageModalIsOpen={messageModalIsOpen}
        />
      ) : null}
      <DashboardMenu route={route} role={role} />
      <div className="dashboard-content">
        <Switch>
          <Route
            exact
            path="/dashboard"
            component={() => (
              <DashHome
                handleToggleSignalModal={handleToggleSignalModal}
                role={role}
              />
            )}
          />
          <Route
            exact
            path="/dashboard/trade-interface"
            component={() => <DashTradeInterface role={role} />}
          />
          <Route
            exact
            path="/dashboard/signal-history/:page"
            component={() => (
              <DashSignalHistory
                handleToggleSignalModal={handleToggleSignalModal}
                signalModalIsOpen={signalModalIsOpen}
                role={role}
              />
            )}
          />
          <Route
            path="/dashboard/activity-and-notifications/:page"
            component={() => <DashActivity />}
          />
          <Route
            path="/dashboard/settings"
            component={() => <DashSettings role={role} />}
          />
        </Switch>
      </div>
    </main>
  );
};

const mapDispatchToProps = dispatch => ({
  closeSnackbar: () => dispatch(closeSnackbar()),
});

const mapStateToProps = ({ dashMenu, messageModal, snackbar }) => ({
  dashMenuIsOpen: dashMenu.isOpen,
  messageModalIsOpen: messageModal.isOpen,
  snackbarIsOpen: snackbar.isOpen,
  snackbarMessage: snackbar.message,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withRouter(Dashboard));
