import React from 'react';
import { Avatar } from '@material-ui/core';
import { connect } from 'react-redux';

import { ReactComponent as ActivityLockIcon } from '../../../../static/svg/activity-lock-icon.svg';
import { ReactComponent as ChatIcon } from '../../../../static/svg/chat-icon.svg';
import { toggleMessageModal } from '../../../../redux/MessageModal/message-modal.actions';

import './ActivityTableRow.scss';

const ActivityTableRow = ({ data, index, toggleMessageModal }) => {
  const darkBgClass = index % 2 !== 0 ? '' : 'activity-table-row--dark';

  return (
    <div className={`activity-table-row ${darkBgClass}`}>
      <div className="activity-table-row__item activity-table-row__item--username">
        <div className="activity-table-row__avatar-group">
          <div className="activity-table-row__avatar-status" />
          <Avatar
            src={data.src}
            alt={data.alt}
            className="activity-table-row__avatar"
          />
        </div>
        <span>{data.username}</span>
      </div>
      <span className="activity-table-row__item">
        <span className="activity-table-row__mobile-label">last login</span>
        {data.lastLogin}
      </span>
      <span className="activity-table-row__item">
        <span className="activity-table-row__mobile-label">registration</span>
        {data.registrationDate}
      </span>

      <div className="activity-table-row__button-container">
        <span className="activity-table-row__item">
          <ChatIcon onClick={toggleMessageModal} />
        </span>
        <span className="activity-table-row__item">
          <ActivityLockIcon />
        </span>
      </div>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  toggleMessageModal: () => dispatch(toggleMessageModal()),
});

export default connect(
  null,
  mapDispatchToProps,
)(ActivityTableRow);
