const questionFormData = {
  risk: {
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
    answers: ['high', 'medium', 'low'],
  },

  pairPreference: {
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
    answers: ['top', 'average', 'bottom'],
  },

  rateOfReturn: {
    question:
      'Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua?',
    answers: ['fast', 'moderate', 'slow'],
  },
};

export default questionFormData;
