import Moment from 'moment';

import { ReactComponent as DollarIcon } from '../../static/svg/dollar-icon.svg';
import { ReactComponent as TradeIcon } from '../../static/svg/trade-icon.svg';
import { ReactComponent as PortfolioValueIcon } from '../../static/svg/portfolio-value-icon.svg';
import { ReactComponent as PortfolioValueBtcIcon } from '../../static/svg/portfolio-value-btc-icon.svg';
import { ReactComponent as TradeOfTheDayIcon } from '../../static/svg/trade-of-the-day-icon.svg';

const date = 1562011505000;

const createProfitData = () => {
  const data = [];
  for (let i = 0; i <= 24; i++) {
    if (i % 5 === 0)
      data.push({
        x: date + i * 108000000,
        y: Math.random() * -5,
      });
    else
      data.push({
        x: date + i * 108000000,
        y: Math.random() * 8,
      });
  }
  return data;
};

const createTradeFreqData = () => {
  const data = [];
  for (let i = 0; i <= 24; i++) {
    data.push({
      x: date + i * 108000000,
      y: Math.floor(Math.random() * 4),
    });
  }

  return data;
};

const createTradeFreqBarData = () => {
  const data = [];
  for (let i = 0; i <= 24; i++) {
    const genData = Math.floor(Math.random() * 15);
    if (genData !== 0)
      data.push({
        time: Moment(date + i * 108000000).format('M/D'),
        Trades: genData,
      });
  }
  return data;
};

const profitDataArray = createProfitData();
const tradeFreqData = createTradeFreqData();

const statArray = [
  {
    svg: DollarIcon,
    number: '$1.3k',
    description: 'net profit',
    color: 'green',
  },
  {
    svg: TradeIcon,
    number: '34',
    description: 'trades',
    color: 'blue',
  },
  {
    svg: PortfolioValueIcon,
    number: '$20k',
    description: 'portfolio value',
    color: 'red',
  },
  {
    svg: PortfolioValueBtcIcon,
    number: '2.1 BTC',
    description: 'portfolio in btc',
    color: 'yellow',
  },
  {
    svg: TradeOfTheDayIcon,
    description: 'trade of the day',
    number: '6gCrw2kRUAF9CvJDGP16IP',
    color: 'violet',
  },
];

const profitData = [
  {
    id: 'Profit (BTC)',
    data: profitDataArray,
  },
];

const allocationData = [
  {
    id: 'BTC',
    label: 'Bitcoin',
    value: 540,
    color: 'hsl(141, 70%, 50%)',
  },
  {
    id: 'ETH',
    label: 'Etherium',
    value: 571,
    color: 'hsl(34, 70%, 50%)',
  },
  {
    id: 'LTC',
    label: 'Litecoin',
    value: 533,
    color: 'hsl(9, 70%, 50%)',
  },
  {
    id: 'XMR',
    label: 'Monero',
    value: 369,
    color: 'hsl(166, 70%, 50%)',
  },
];

const tradeFreqDataSet = [
  {
    id: 'Number of Trades',
    data: tradeFreqData,
  },
];

const tradeFreqBarData = createTradeFreqBarData();

export {
  statArray,
  profitData,
  allocationData,
  tradeFreqDataSet,
  tradeFreqBarData,
};
