import Brews from '../../../static/img/brews.png';
import Bchains from '../../../static/img/bchains.png';
import Bitwolf from '../../../static/img/bitwolf.png';
import Samurai from '../../../static/img/samurai.png';
import Hope from '../../../static/img/hope.png';

const featureArray = [
  {
    title: 'Easy To Use',
    description:
      'In just 5 minutes, you can get registered, connect your Binance account, set up your trading profile, and start earning through trades.',
    src: Brews,
    alt: 'A leaping panda in a yellow ninja outfit',
  },
  {
    title: 'No Subscriptions',
    description:
      'Our platform is free to use until you make money. We don’t see returns until you make a profit. There are no subscriptions or monthly payments.',
    src: Bchains,
    alt: 'A red ninja with blockchain nunchucks.',
  },
  {
    title: 'Up To Date Statistics',
    description:
      'Log in to our web application to see how many trades we’ve made for you and your profit margin. Sort through the data and visualize how your account is earning.',
    src: Bitwolf,
    alt: 'A wolf in a ninja outfit with glowing red hands.',
  },
  {
    title: 'Personalized Notifications',
    description:
      'We will send you notifications on your account via your preferred method (email, discord, telegram). We also have a discord server where you can chat with a ninja live.',
    src: Samurai,
    alt: 'A samurai in a red outfit.',
  },
  {
    title: 'Secure Signals',
    description:
      'Your account credentials are of utmost importance to us. We’ve made sure to implement safety measures in every step of the process.',
    src: Hope,
    alt: 'A ninja weilding a sword and bitcoin shield.',
  },
];

export default featureArray;
