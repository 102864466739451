import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';

import Button from '../../../components/Button';
import { openSnackbar } from '../../../redux/Snackbar/snackbar.actions';

import './PasswordForm.scss';

const initialState = {
  newPassword: '',
  confirmNewPassword: '',
  currentPassword: '',
};

const PasswordForm = ({ openSnackbar }) => {
  const [inputFields, setInputFields] = useState(initialState);
  const [errors, setErrors] = useState(initialState);

  const handleInputChange = e => {
    e.persist();
    setInputFields(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleFormSubmit = e => {
    e.preventDefault();

    let count = 0;

    for (let field in inputFields) {
      let validated = !inputFields[field] ? 'field is required' : '';
      setErrors(prevState => ({ ...prevState, [field]: validated }));
      if (validated.length) count++;
    }
    if (count === 0 && inputFields.currentPassword) {
      setInputFields(initialState);
      openSnackbar('Password Saved');
      // Send to back end for validation
    }
  };

  return (
    <div className="password-form custom-mui-form">
      <form onSubmit={handleFormSubmit} className="custom-mui-form__form">
        <TextField
          id="new-password"
          type="password"
          variant="standard"
          label="New Password"
          fullWidth={true}
          name="newPassword"
          onChange={handleInputChange}
          value={inputFields.newPassword}
          helperText={errors.newPassword || ''}
          error={errors.newPassword ? true : false}
        />
        <TextField
          id="new-password-confirm"
          type="password"
          variant="standard"
          label="Confirm Password"
          fullWidth={true}
          name="confirmNewPassword"
          onChange={handleInputChange}
          value={inputFields.confirmNewPassword}
          helperText={errors.confirmNewPassword || ''}
          error={errors.confirmNewPassword ? true : false}
        />

        <TextField
          id="current-password"
          type="password"
          variant="standard"
          label="Curent Password"
          fullWidth={true}
          name="currentPassword"
          onChange={handleInputChange}
          value={inputFields.currentPassword}
          helperText={errors.currentPassword || ''}
          error={errors.currentPassword ? true : false}
        />
        <Button
          type="solid"
          header={false}
          element="input"
          value="Change Password"
        />
      </form>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  openSnackbar: message => dispatch(openSnackbar(message)),
});

export default connect(
  null,
  mapDispatchToProps,
)(PasswordForm);
