import React, { useEffect, useState } from 'react';
import Moment from 'moment';
import { ResponsiveLine } from '@nivo/line';

import './PerformanceChart.scss';

const PerformanceChart = ({ data }) => {
  const memo = [];

  const [cursorIsOnLeftSideOfScreen, setCursorIsOnLeftSideOfScreen] = useState(
    false,
  );

  const [toolTipTransformValue, setToolTipTransformValue] = useState(0);

  useEffect(() => {
    const handleWindowScroll = e => {
      const chartBounds = document
        .querySelector('.performance-chart')
        .getBoundingClientRect();

      const leftSideOfChart = chartBounds.left + chartBounds.width / 2;

      if (e.screenX > leftSideOfChart && cursorIsOnLeftSideOfScreen)
        setCursorIsOnLeftSideOfScreen(false);
      else if (e.screenX <= leftSideOfChart && !cursorIsOnLeftSideOfScreen)
        setCursorIsOnLeftSideOfScreen(true);
    };
    window.addEventListener('mousemove', handleWindowScroll);

    setToolTipTransformValue(cursorIsOnLeftSideOfScreen ? 0 : -110);

    return () => {
      window.removeEventListener('mousemove', handleWindowScroll);
    };
  }, [cursorIsOnLeftSideOfScreen, setCursorIsOnLeftSideOfScreen]);
  return (
    <div className="performance-chart">
      <ResponsiveLine
        data={data}
        margin={{ top: 45, right: 20, bottom: 50, left: 35 }}
        xScale={{ type: 'point' }}
        yScale={{ type: 'linear', stacked: false, min: 'auto', max: 'auto' }}
        enableSlices="x"
        enableGridX={false}
        crosshairType="x"
        axisTop={null}
        axisRight={null}
        axisBottom={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 90,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle',
          format: d => {
            let formatted;
            const date = Moment(d).format('M/D');
            if (!memo.includes(date)) {
              memo.push(date);
              formatted = date;
            } else {
              formatted = null;
            }

            return formatted;
          },
        }}
        axisLeft={{
          orient: 'left',
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: '',
          legendOffset: -40,
          legendPosition: 'middle',
        }}
        sliceTooltip={({ slice }) => {
          return (
            <div
              style={{
                background: 'white',
                padding: '9px 12px',
                border: '1px solid #ccc',
                transform: `translateX(${toolTipTransformValue}%)`,
                boxShadow: '1px 1px 2px rgba(0,0,0,0.2)',
              }}
            >
              {slice.points.map(point => (
                <div
                  key={point.id}
                  style={{
                    padding: '3px 0',
                    display: 'flex',
                    flexDirection: 'column',
                  }}
                >
                  <div
                    style={{
                      marginBottom: '10px',
                      maxWidth: '150px',
                      lineHeight: '170%',
                    }}
                  >
                    <strong>Date:</strong>{' '}
                    {Moment(point.data.xFormatted).format(
                      'MMMM Do YYYY, h:mm:ss a',
                    )}
                  </div>
                  <div>
                    <strong>Profit:</strong> {point.data.yFormatted.toFixed(1)}{' '}
                    BTC
                  </div>
                </div>
              ))}
            </div>
          );
        }}
        colors={{ scheme: 'nivo' }}
        enablePoints={false}
        pointColor={{ theme: 'background' }}
        pointBorderWidth={2}
        pointBorderColor={{ from: 'serieColor' }}
        pointLabel="y"
        pointLabelYOffset={-12}
        enableArea={true}
        areaOpacity={0.8}
        useMesh={true}
        legends={[
          {
            anchor: 'top-left',
            direction: 'row',
            justify: false,
            translateX: 0,
            translateY: -30,
            itemsSpacing: 0,
            itemDirection: 'left-to-right',
            itemWidth: 80,
            itemHeight: 20,
            itemOpacity: 0.75,
            symbolSize: 12,
            symbolShape: 'circle',
            symbolBorderColor: 'rgba(0, 0, 0, .5)',
            effects: [
              {
                on: 'hover',
                style: {
                  itemBackground: 'rgba(0, 0, 0, .03)',
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
      />
    </div>
  );
};

export default PerformanceChart;
