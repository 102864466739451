import React from 'react';
import PropTypes from 'prop-types';

import { Select, MenuItem, FormControl, InputLabel } from '@material-ui/core';

import './SelectInput.scss';

const SelectInput = ({
  onChange,
  menuItemArray,
  label = '',
  id,
  name,
  value,
  classNames = '',
  disabled,
}) => {
  return (
    <FormControl
      className={`dash-trade-interface__${name}-select custom-mui-select ${classNames}`}
    >
      <InputLabel htmlFor={id}>{label}</InputLabel>
      <Select
        onChange={onChange}
        value={value}
        disabled={disabled}
        inputProps={{
          name,
          id,
        }}
      >
        {menuItemArray.map(item => (
          <MenuItem key={item} value={item}>
            {item}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};

SelectInput.propTypes = {
  onChange: PropTypes.func.isRequired,
  menuItemArray: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  label: PropTypes.string,
};

export default SelectInput;
