import { combineReducers } from 'redux';

import dashMenuReducer from './DashMenu/dash-menu.reducer';
import messageModalReducer from './MessageModal/message-modal.reducer';
import snackbarReducer from './Snackbar/snackbar.reducer';

export default combineReducers({
  dashMenu: dashMenuReducer,
  messageModal: messageModalReducer,
  snackbar: snackbarReducer,
});
