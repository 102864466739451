import React, { useEffect, useState } from 'react';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';

import '../../scss/typography.scss';
import './PageTitle.scss';

const PageTitle = ({ title, handleEnterBounds, handleLeaveBounds }) => {
  const [verticalScrollPos, setVerticalScrollPos] = useState(window.scrollY);
  title = title.toLowerCase();

  let bgImgClass;

  if (title === 'about us') bgImgClass = 'page-title--about-us';
  if (title === 'guide') bgImgClass = 'page-title--guide';
  if (title === 'contact us') bgImgClass = 'page-title--contact-us';

  const handleWindowScroll = e => {
    const scrollY = e.currentTarget.scrollY;
    if (scrollY < 300) setVerticalScrollPos(scrollY);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleWindowScroll);

    return () => {
      window.removeEventListener('scroll', handleWindowScroll);
    };
  }, []);
  return (
    <Waypoint
      onEnter={handleEnterBounds}
      onLeave={handleLeaveBounds}
      topOffset="5%"
    >
      <div className={`page-title ${bgImgClass}`}>
        <h2
          className="section-headline section-headline--white"
          style={{
            position: 'relative',
            top: `-${(verticalScrollPos + 1) / 3}%`,
          }}
        >
          {title}
        </h2>
      </div>
    </Waypoint>
  );
};

PageTitle.propTypes = {
  title: PropTypes.string.isRequired,
  handleEnterBounds: PropTypes.func,
  handleLeaveBounds: PropTypes.func,
};

export default PageTitle;
