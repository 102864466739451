import React from 'react';
import TextField from '@material-ui/core/TextField';

import Button from '../../components/Button';
import PageTitle from '../../components/PageTitle';
import RegisterCTA from '../../components/RegisterCTA';
import NinjaDiscordIcon from '../../static/svg/ninja-discord-icon.svg';

import './ContactUs.scss';

const ContactUs = () => {
  return (
    <main className="contact-us">
      <PageTitle title="Contact Us" />
      <section className="contact-us__message">
        <h3 className="sub-section-headline">Send us a message</h3>
        <form className="contact-us__form">
          <div className="contact-us__form-row contact-us__form-row--top">
            <TextField
              id="name"
              type="text"
              variant="standard"
              label="your name*"
              fullWidth={true}
              name="name"
            />
            <TextField
              id="email"
              type="text"
              variant="standard"
              label="your email*"
              fullWidth={true}
              name="email"
            />
          </div>
          <div className="contact-us__form-row contact-us__form-row--bottom">
            <TextField
              id="message"
              type="text"
              variant="standard"
              label="your message*"
              multiline={true}
              rowsMax={10}
              fullWidth={true}
              name="message"
            />
          </div>
          <Button
            type="solid"
            header={false}
            element="input"
            value="Send Message"
          />
        </form>
      </section>
      <section className="contact-us__discord">
        <h3 className="sub-section-headline">
          Chat with a ninja on our discord server
        </h3>
        <Button type="solid" header={false} element="link" href="#">
          <img src={NinjaDiscordIcon} alt="discord logo" /> Chat on discord
        </Button>
      </section>
      <RegisterCTA />
    </main>
  );
};

export default ContactUs;
