import React, { useEffect } from 'react';
import Avatar from '@material-ui/core/Avatar';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import HomeIcon from './HomeIcon';
import TradeInterfaceIcon from './TradeInterfaceIcon';
import SignalHistoryIcon from './SignalHistoryIcon';
import ActivityNotificationsIcon from './ActivityNotificationsIcon';
import SettingsIcon from './SettingsIcon';
import LogOutIcon from './LogOutIcon';
import { ReactComponent as ChevronIcon } from '../../../static/svg/chevron-right.svg';
import AvatarImg from '../../../static/img/avatar.jpg';
import { toggleDashMenuOpen } from '../../../redux/DashMenu/dash-menu.actions';

import './DashboardMenu.scss';

const DashboardMenu = ({ route, role, isFixed, isOpen, toggleDashMenu }) => {
  const handleClickMenuLink = () => {
    if (window.innerWidth < 900) toggleDashMenu();
  };

  const returnLinkActiveClass = link => (link === route ? 'active' : '');
  const dashMenuOpenClass = isOpen ? 'menu-open' : '';
  const dashMenuPositionClass = isFixed ? '' : 'position-static';

  useEffect(() => {
    const handleWindowResize = e => {
      if (isOpen && e.innerWidth > 900) toggleDashMenu();
    };

    const handleClickOnBackground = e => {
      const { classList } = e.target;

      if (classList.contains('dashboard') && isOpen) toggleDashMenu();
    };

    window.addEventListener('resize', handleWindowResize);
    window.addEventListener('click', handleClickOnBackground);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
      window.removeEventListener('click', handleClickOnBackground);
    };
  });
  return (
    <div
      className={`dashboard-menu ${dashMenuOpenClass} ${dashMenuPositionClass}`}
    >
      <ChevronIcon
        onClick={toggleDashMenu}
        className={`dashboard-menu__back-button ${dashMenuOpenClass}`}
      />
      <div className="dashboard-menu__avatar-group">
        <Avatar
          className="dashboard-menu__avatar"
          src={AvatarImg}
          alt="Gravatar image"
        />
        <span className="dashboard-menu__username">username21</span>
        {role === 'signaler' ? (
          <span className="dashboard-menu__role">signaler</span>
        ) : null}
        {role === 'superAdmin' ? (
          <div>
            <span className="dashboard-menu__role">super</span>
            <span className="dashboard-menu__role dashboard-menu__role--emphasized">
              Admin
            </span>
          </div>
        ) : null}
      </div>
      <nav>
        <ul className="dashboard-menu__nav-list">
          <li>
            <Link onClick={handleClickMenuLink} to="/dashboard">
              <span
                className={`dashboard-menu__link ${returnLinkActiveClass(
                  'dashboard',
                )}`}
              >
                <HomeIcon />
                Home
              </span>{' '}
            </Link>
          </li>
          {role === 'signaler' || role === 'superAdmin' ? (
            <li>
              <Link
                onClick={handleClickMenuLink}
                to="/dashboard/trade-interface"
              >
                <span
                  className={`dashboard-menu__link ${returnLinkActiveClass(
                    'trade-interface',
                  )}`}
                >
                  <TradeInterfaceIcon />
                  Trade Interface
                </span>{' '}
              </Link>
            </li>
          ) : null}

          <li>
            <Link
              onClick={handleClickMenuLink}
              to="/dashboard/signal-history/1"
            >
              <span
                className={`dashboard-menu__link ${returnLinkActiveClass(
                  'signal-history',
                )}`}
              >
                <SignalHistoryIcon /> Signal History
              </span>
            </Link>
          </li>
          {role === 'superAdmin' ? (
            <li>
              <Link
                onClick={handleClickMenuLink}
                to="/dashboard/activity-and-notifications/1"
              >
                <span
                  className={`dashboard-menu__link ${returnLinkActiveClass(
                    'activity-and-notifications',
                  )}`}
                >
                  <ActivityNotificationsIcon classes="dashboard-menu__link-icon dashboard-menu__link-icon--height" />
                  Activity and Notifications
                </span>
              </Link>
            </li>
          ) : null}
          <li>
            <Link onClick={handleClickMenuLink} to="/dashboard/settings">
              <span
                className={`dashboard-menu__link ${returnLinkActiveClass(
                  'settings',
                )}`}
              >
                <SettingsIcon />
                Settings
              </span>
            </Link>
          </li>
          <li tabIndex={0} onClick={handleClickMenuLink}>
            <Link to="/auth/login">
              <span className="dashboard-menu__link dashboard-menu__link--logout">
                <LogOutIcon />
                Log Out
              </span>
            </Link>
          </li>
        </ul>
      </nav>
    </div>
  );
};

DashboardMenu.propTypes = {
  route: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
};

const mapStateToProps = ({ dashMenu: { isFixed, isOpen } }) => ({
  isFixed,
  isOpen,
});

const mapDispatchToProps = dispatch => ({
  toggleDashMenu: () => dispatch(toggleDashMenuOpen()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(DashboardMenu);
