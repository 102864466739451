import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Button from '../../components/Button';
import DashComponent from '../../components/Dashboard/DashComponent';
import AutoCompleteInput from './AutoCompleteInput';
import SelectInput from '../../components/SelectInput';
import { openSnackbar } from '../../redux/Snackbar/snackbar.actions';

import { usersArray, userProfileData, orderTypeArray } from './seedData';
import { ReactComponent as TradeInterfaceIcon } from '../../static/svg/trade-interface-icon-white.svg';

import './DashTradeInterface.scss';

const signalFormInitialState = {
  symbol: '',
  side: '',
  amount: '',
  type: '',
  timeInForce: '',
  stopPrice: '',
  icebergQuantity: '',
};

const DashTradeInterface = ({ role, openSnackbar }) => {
  const [signalFormInputFields, setSignalFormInputFields] = useState(
    signalFormInitialState,
  );
  const [user, setUser] = useState('');

  const handleUserFormInputChange = e => setUser(e.target.value);

  const handleSignalFormInputChange = e => {
    e.persist();
    setSignalFormInputFields(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleAutocompleteChange = (e, { newValue }) => {
    e.persist();
    setSignalFormInputFields(prevState => ({
      ...prevState,
      [e.target.name]: newValue,
    }));
  };

  const handleAutocompleteClick = parts => {
    const combineParts = parts => parts.map(part => part.text).join('');

    setSignalFormInputFields(prevState => ({
      ...prevState,
      symbol: combineParts(parts),
    }));
  };

  const handleSignalFormSubmit = e => {
    e.preventDefault();
    openSnackbar('Signal Sent');
    console.log('submitted');
  };

  // This is needed to stop the form from submitting when enter is pressed to select an option
  // from the autocomplete form
  const handleEnterKeyPress = e => {
    if (e.which === 13) e.preventDefault();
  };

  const renderTimeInForceInput = () => {
    if (
      type === 'limit' ||
      type === 'stop loss limit' ||
      type === 'take profit limit'
    )
      return (
        <SelectInput
          onChange={handleSignalFormInputChange}
          menuItemArray={['GTC', 'IOC', 'FOK']}
          id="dash-trade-interface-time-in-force"
          value={signalFormInputFields.timeInForce}
          name="timeInForce"
          label="Time In Force"
        />
      );
    else return null;
  };

  const renderPriceInput = () => {
    if (
      type === 'limit' ||
      type === 'stop loss limit' ||
      type === 'take profit limit' ||
      type === 'limit maker'
    )
      return (
        <TextField
          id="dash-trade-interface-price-input"
          type="number"
          variant="standard"
          label="price"
          name="price"
          onChange={handleSignalFormInputChange}
          value={signalFormInputFields.price}
          inputProps={{ step: '0.001', min: '0' }}
        />
      );
    else return null;
  };

  const renderStopPriceInput = () => {
    if (
      type === 'stop loss' ||
      type === 'stop loss limit' ||
      type === 'take profit limit' ||
      type === 'take profit'
    )
      return (
        <TextField
          id="dash-trade-interface-stop-price-input"
          type="number"
          variant="standard"
          label="stop price"
          name="stopPrice"
          onChange={handleSignalFormInputChange}
          value={signalFormInputFields.stopPrice}
          inputProps={{ step: '0.001', min: '0' }}
        />
      );
    else return null;
  };

  const renderIcebergQuantityInput = () => {
    if (
      type === 'limit' ||
      type === 'stop loss limit' ||
      type === 'take profit limit'
    )
      return (
        <TextField
          id="dash-trade-interface-iceberg-quantity-input"
          type="number"
          variant="standard"
          label="iceberg qty"
          name="icebergQuantity"
          onChange={handleSignalFormInputChange}
          value={signalFormInputFields.icebergQuantity}
          inputProps={{ step: '0.001', min: '0' }}
        />
      );
    else return null;
  };

  const { type } = signalFormInputFields;

  return (
    <section className="dash-trade-interface">
      <DashComponent
        svg={<TradeInterfaceIcon />}
        role={role}
        title="trade interface"
      >
        <div className="dash-trade-interface__load-user">
          <SelectInput
            onChange={handleUserFormInputChange}
            menuItemArray={usersArray}
            id="dash-trade-interface-user"
            value={user}
            name="user"
            label="User"
          />

          <div className="dash-trade-interface__user-profile">
            <div className="dash-trade-interface__user-profile-data">
              <span>risk per trade</span>
              <span>{userProfileData.riskPerTrade}</span>
            </div>

            <div className="dash-trade-interface__user-profile-data">
              <span>pairing preference</span>
              <span>{userProfileData.pairingPreference}</span>
            </div>

            <div className="dash-trade-interface__user-profile-data">
              <span>rate of return</span>
              <span>{userProfileData.rateOfReturn}</span>
            </div>
          </div>
          <form
            onKeyPress={handleEnterKeyPress}
            onSubmit={handleSignalFormSubmit}
            className="dash-trade-interface__signal-form custom-mui-form custom-mui-form--half-width"
          >
            <div className="dash-trade-interface__input-row ">
              <AutoCompleteInput
                handleChange={handleAutocompleteChange}
                handleClick={handleAutocompleteClick}
                value={signalFormInputFields.symbol}
              />
              <SelectInput
                onChange={handleSignalFormInputChange}
                menuItemArray={['sell', 'buy']}
                id="dash-trade-interface-side-input"
                value={signalFormInputFields.side}
                name="side"
                label="side"
              />
            </div>
            <div className="dash-trade-interface__input-row">
              <TextField
                id="dash-trade-interface-amount-input"
                type="number"
                variant="standard"
                label="amount"
                name="amount"
                onChange={handleSignalFormInputChange}
                value={signalFormInputFields.amount}
                inputProps={{ step: '0.001', min: '0' }}
              />

              <SelectInput
                onChange={handleSignalFormInputChange}
                menuItemArray={orderTypeArray}
                id="dash-trade-interface-side-type"
                value={signalFormInputFields.type}
                name="type"
                label="type"
              />
            </div>
            <div className="dash-trade-interface__input-row dash-trade-interface__input-row--three">
              {renderTimeInForceInput()}
              {renderStopPriceInput()}
              {renderPriceInput()}
              {renderIcebergQuantityInput()}
            </div>

            <Button
              type="solid"
              header={false}
              element="input"
              value="Dispatch"
            />
          </form>
        </div>
      </DashComponent>
    </section>
  );
};

const mapDispatchToProps = dispatch => ({
  openSnackbar: message => dispatch(openSnackbar(message)),
});

export default connect(
  null,
  mapDispatchToProps,
)(DashTradeInterface);
