import React from 'react';
import { HashLink } from 'react-router-hash-link';
import Scrollspy from 'react-scrollspy';
import PropTypes from 'prop-types';

import './GuideMenu.scss';

const GuideMenu = ({
  isMenuFixed,
  isTopBound,
  isMenuOpen,
  handleToggleMenu,
}) => {
  const positionFixedClass = isMenuFixed ? 'menu-fixed' : '';
  const positionMenuTopClass = isTopBound ? 'menu-top' : '';
  const menuOpenClass = isMenuOpen ? 'menu-open' : '';

  return (
    <div
      className={`guide-menu ${positionFixedClass} ${positionMenuTopClass} ${menuOpenClass}`}
    >
      <nav className="guide-menu__nav">
        <ul className="guide-menu__nav-list">
          <Scrollspy
            items={[
              'overview-how-to-set-up-an-account',
              'register-with-your-email-address',
              'connect-your-binance-account',
              'register-or-login-with-your-binance-account',
            ]}
            currentClassName="active"
            componentTag="div"
          >
            <li onClick={handleToggleMenu} className="guide-menu__primary-link">
              <HashLink smooth to="/guide#overview-how-to-set-up-an-account">
                overview: how to set up an account
              </HashLink>
            </li>
            <li onClick={handleToggleMenu} className="guide-menu__primary-link">
              <HashLink smooth to="/guide#register-with-your-email-address">
                register with your email address
              </HashLink>
            </li>
            <li onClick={handleToggleMenu} className="guide-menu__primary-link">
              <HashLink smooth to="/guide#connect-your-binance-account">
                connect your binance account
              </HashLink>
            </li>
            <li
              onClick={handleToggleMenu}
              className="guide-menu__secondary-link"
            >
              <HashLink
                smooth
                to="/guide#register-or-login-with-your-binance-account"
              >
                register or login with your binance account
              </HashLink>
            </li>
            <li
              onClick={handleToggleMenu}
              className="guide-menu__secondary-link"
            >
              <HashLink smooth to="/guide#generate-your-api-key-and-secret">
                generate an API key and a secret
              </HashLink>
            </li>
            <li
              onClick={handleToggleMenu}
              className="guide-menu__secondary-link"
            >
              <HashLink
                smooth
                to="/guide#enter-your-api-key-and-secret-in-the-dashboard-settings"
              >
                enter your api key and secret in the dashboard settings
              </HashLink>
            </li>
            <li onClick={handleToggleMenu} className="guide-menu__primary-link">
              <HashLink smooth to="/guide#set-up-your-trading-profile">
                set up your trading profile
              </HashLink>
            </li>
            <li onClick={handleToggleMenu} className="guide-menu__primary-link">
              <HashLink smooth to="/guide#set-up-notifications">
                set up notifications
              </HashLink>
            </li>
            <li
              onClick={handleToggleMenu}
              className="guide-menu__secondary-link"
            >
              <HashLink smooth to="/guide#email">
                email
              </HashLink>
            </li>
            <li
              onClick={handleToggleMenu}
              className="guide-menu__secondary-link"
            >
              <HashLink smooth to="/guide#discord">
                discord
              </HashLink>
            </li>
            <li
              onClick={handleToggleMenu}
              className="guide-menu__secondary-link"
            >
              <HashLink smooth to="/guide#telegram">
                telegram
              </HashLink>
            </li>
            <li onClick={handleToggleMenu} className="guide-menu__primary-link">
              <HashLink smooth to="/guide#FAQ">
                FAQ
              </HashLink>
            </li>
          </Scrollspy>
        </ul>
      </nav>
    </div>
  );
};

GuideMenu.propTypes = {
  isMenuFixed: PropTypes.bool.isRequired,
  isTopBound: PropTypes.bool.isRequired,
  isMenuOpen: PropTypes.bool.isRequired,
  handleToggleMenu: PropTypes.func.isRequired,
};

export default GuideMenu;
