import React, { useRef } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';

import OverviewFigure from '../../../static/svg/overview-figure.svg';
import RegisterFigure from '../../../static/img/register-figure.png';
import GenKeyFigure from '../../../static/img/gen-key-figure.png';
import NameKeyFigure from '../../../static/img/name-key-figure.png';
import BinanceSettingsFigure from '../../../static/img/binance-settings-figure.png';
import BinanceSaveFigure from '../../../static/img/binance-save-figure.png';

import './GuideContent.scss';

const GuideContent = ({ setMenuPositionOnLoad }) => {
  let contentDivRef = useRef(null);

  const handleOnContentLoad = () => {
    const topBound = contentDivRef.current.getBoundingClientRect().top;
    const bottomBound = contentDivRef.current.getBoundingClientRect().bottom;

    setMenuPositionOnLoad(topBound, bottomBound);
  };
  return (
    <div
      onLoad={handleOnContentLoad}
      ref={contentDivRef}
      className="guide-content"
    >
      <div className="guide-content__layout">
        <article
          className="guide-content__article"
          id="overview-how-to-set-up-an-account"
        >
          <h3 className="sub-section-headline">
            overview: how to set up an account
          </h3>
          <img
            src={OverviewFigure}
            alt="Figure displaying the overview of the registration process"
            className="guide-content__figure"
          />
        </article>
        <article
          className="guide-content__article"
          id="register-with-your-email-address"
        >
          <h3 className="sub-section-headline">
            register with your email address
          </h3>
          <p className="body-text">
            To register an account, just go to our{' '}
            <Link to="/registration">registration</Link> page and fill out the
            form. Then, you will receive an email with a verification link. Just
            click the link and you will have full access to your account. Make
            sure to set up your trading profile and link your binance account to
            take full advantage of our services!
          </p>
        </article>

        <article
          className="guide-content__article"
          id="connect-your-binance-account"
        >
          <h3 className="sub-section-headline">connect your binance account</h3>
          <p className="body-text">
            For an account to be considered "tradable" by our platform you must
            connect it to your Binance account. This is done by simply
            generating an API key and a secret through Binance and then logging
            into your Altninja Trader account and entering your newly generated
            Binance credentials in the account settings section of your user
            dashboard.
          </p>
          <div
            className="guide-content__article-step"
            id="register-or-login-with-your-binance-account"
          >
            <h4 className="step-headline">
              {`1) register or login with your binance account`}
            </h4>
            <img
              src={RegisterFigure}
              alt="Binance login form"
              className="guide-content__figure"
            />
          </div>
          <div
            className="guide-content__article-step"
            id="generate-your-api-key-and-secret"
          >
            <h4 className="step-headline">
              {`2) generate your api key and secret`}
            </h4>
            <p className="body-text">
              Navigate to the account settings page. This appears when you first
              login to your Binance account. If you don't see this page go to
              the top right and select the user icon and then "Account" and you
              will be taken there.
            </p>
            <img
              src={GenKeyFigure}
              alt="Binance dashboard with API settings button circled"
              className="guide-content__figure"
            />

            <p className="body-text">Go to API settings (circled above).</p>
            <p className="body-text">
              Name your key and press submit. You will need to enable 2FA before
              this step.
            </p>
            <img
              src={NameKeyFigure}
              alt="Binance create new key form"
              className="guide-content__figure"
            />
            <p className="body-text">
              Copy your API key and secret. Make sure to store the secret in a
              safe location once added to the Ninja Trade App. Binance only
              shows the secret when you create the key so be sure to hold on to
              it.
            </p>
            <img
              src={BinanceSettingsFigure}
              alt="Binance API key settings"
              className="guide-content__figure"
            />
            <p className="body-text">
              Press edit and then insure Trading is enabled and Withdrawals are
              disabled. Please make sure that you you leave IP Access
              Restriction as Unrestricted for Binance.
            </p>
            <img
              src={BinanceSaveFigure}
              alt="Binance API key settings"
              className="guide-content__figure"
            />
            <p className="body-text">Make sure to press "Save".</p>
            <p className="body-text">
              Your Binance trading API is now created and is ready to be added
              to the Ninja Trade App.
            </p>
          </div>
        </article>
      </div>
    </div>
  );
};

GuideContent.propTypes = {
  setMenuPositionOnLoad: PropTypes.func.isRequired,
};

export default GuideContent;
