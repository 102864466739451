import React from 'react';

import './StatBox.scss';

const StatBox = ({ stat, role, handleToggleSignalModal }) => {
  let statColorClass;
  switch (stat.color) {
    case 'green':
      statColorClass = 'stat-box__stat--green';
      break;
    case 'blue':
      statColorClass = 'stat-box__stat--blue';
      break;
    case 'red':
      statColorClass = 'stat-box__stat--red';
      break;
    case 'yellow':
      statColorClass = 'stat-box__stat--yellow';
      break;
    case 'violet':
      statColorClass = 'stat-box__stat--violet';
      break;

    default:
      statColorClass = 'stat-box__stat--white';
  }

  const statCOIDClass =
    stat.description === 'trade of the day' ? 'stat-box__stat--coid' : '';

  const cursorPointerClass =
    stat.description === 'trade of the day' ? 'stat-box--cursor-pointer' : '';

  const halfOfContainerWidthClass =
    role === 'user' ? '' : 'stat-box--half-of-container';
  return (
    <div
      onClick={handleToggleSignalModal ? handleToggleSignalModal : null}
      className={`stat-box ${halfOfContainerWidthClass} ${cursorPointerClass}`}
    >
      <div className="stat-box__icon-container">
        <stat.svg />
      </div>
      <div className="stat-box__content">
        <span className={`stat-box__stat ${statColorClass} ${statCOIDClass}`}>
          {stat.number}
        </span>
        <span className="stat-box__stat-description">{stat.description}</span>
      </div>
    </div>
  );
};

export default StatBox;
