import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';

import Feature from './Feature';
import featureArray from './featureSeedData';

import './Features.scss';

const Features = () => {
  const [isHeadlineVisible, setIsHeadlineVisible] = useState(false);

  // Feature render functions, data is seeded from featureSeedData.js
  // I wrote the code this way so that in the future, data can be pulled in from CMS
  const renderLastTwoFeatures = () => {
    return featureArray.map((feature, index) => {
      let components = [];
      if (index >= featureArray.length - 2)
        components.push(<Feature feature={feature} key={feature.title} />);

      return components;
    });
  };

  const renderFeatures = () => {
    return featureArray.map((feature, index) => {
      let components = [];
      if (index < featureArray.length - 2)
        components.push(<Feature feature={feature} key={feature.title} />);
      else if (index === featureArray.length - 2) {
        components.push(
          <div key="list-row" className="features__list-row">
            {renderLastTwoFeatures()}
          </div>,
        );
      }
      return components;
    });
  };

  // Waypoints enter handler
  const handleEnterHeadline = () => setIsHeadlineVisible(true);

  // Set up reveal class for headline
  const revealClass = isHeadlineVisible ? 'reveal' : '';

  return (
    <section className="features">
      <div className="features__layout">
        <Waypoint onEnter={handleEnterHeadline} bottomOffset="35%">
          <h2 className={`section-headline hide ${revealClass}`}>
            Trading Crypto For Profit Just Got A Whole Lot Easier
          </h2>
        </Waypoint>

        <ul className="features__list">{renderFeatures()}</ul>
      </div>
    </section>
  );
};

export default Features;
