import React from 'react';
import PropTypes from 'prop-types';

const HomeIcon = ({
  color = '979797',
  classes = 'dashboard-menu__link-icon',
}) => (
  <svg
    width="28"
    height="26"
    viewBox="0 0 28 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      d="M27.2481 10.8361L14.8741 0.359704C14.3072 -0.119901 13.4775 -0.119901 12.9106 0.359704L0.537172 10.8361C-0.102864 11.3771 -0.182235 12.3358 0.359853 12.9758C0.901379 13.6158 1.85946 13.6958 2.50006 13.1531L2.69821 12.9854V25.0515C2.69821 25.5756 3.12265 26 3.64729 26H10.6314V15.5832H17.155V26H24.1386C24.6632 26 25.0888 25.5756 25.0888 25.0515V12.9859L25.2869 13.1537C25.5718 13.3952 25.9213 13.5139 26.2664 13.5139C26.6987 13.5139 27.126 13.3316 27.4266 12.9764C27.9675 12.3358 27.887 11.3771 27.2481 10.8361Z"
      fill={`#${color}`}
    />
  </svg>
);

HomeIcon.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.string,
};

export default HomeIcon;
