import { SnackbarTypes } from './snackbar.types';

export const openSnackbar = message => ({
  type: SnackbarTypes.OPEN_SNACKBAR,
  payload: message,
});

export const closeSnackbar = () => ({
  type: SnackbarTypes.CLOSE_SNACKBAR,
});
