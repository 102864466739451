import React, { useState } from 'react';
import { TextField } from '@material-ui/core';
import { connect } from 'react-redux';

import Button from '../../../components/Button';
import { openSnackbar } from '../../../redux/Snackbar/snackbar.actions';

import './RegisterSignalerForm.scss';

const RegisterSignalerForm = ({ openSnackbar }) => {
  const [email, setEmail] = useState('');

  const handleFormSubmit = e => {
    e.preventDefault();
    setEmail('');
    openSnackbar('email sent');
  };

  const handleInputChange = e => setEmail(e.target.value);

  return (
    <div className="register-signaler-form">
      <p className="body-text">
        Be careful who you send signaler registration links to.{' '}
        <span className="warning">
          This will allow them to register as a signaler and trade for users.
        </span>
      </p>
      <form
        onSubmit={handleFormSubmit}
        className="register-signaler-form__form custom-mui-form"
      >
        <TextField
          id="register-signaler-email-input"
          type="email"
          variant="standard"
          label="email"
          fullWidth={true}
          name="email"
          onChange={handleInputChange}
          value={email}
        />
        <Button type="solid" header={false} element="input" value="send" />
      </form>
    </div>
  );
};

const mapDispatchToProps = dispatch => ({
  openSnackbar: message => dispatch(openSnackbar(message)),
});

export default connect(
  null,
  mapDispatchToProps,
)(RegisterSignalerForm);
