const signalList = [
  {
    symbol: 'ETHBTC',
    side: 'buy',
    amount: 0.2,
    date: '06/11/2019',
    status: 'filled',
    profit: 100,
  },
  {
    symbol: 'BNBBTC',
    side: 'sell',
    amount: 0.1,
    date: '06/12/2019',
    status: 'filled',
    profit: 50,
  },

  {
    symbol: 'DOGEBTC',
    side: 'buy',
    amount: 0.05,
    date: '06/12/2019',
    status: 'filled',
    profit: 200,
  },
  {
    symbol: 'ETHBTC',
    side: 'buy',
    amount: 0.6,
    date: '06/13/2019',
    status: 'filled',
    profit: -200,
  },
  {
    symbol: 'BNBBTC',
    side: 'sell',
    amount: 0.72,
    date: '06/14/2019',
    status: 'filled',
    profit: 9000,
  },
];

export default signalList;
