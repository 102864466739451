import React, { useState } from 'react';
import { Waypoint } from 'react-waypoint';
import PropTypes from 'prop-types';

import './Feature.scss';

const Feature = ({ feature }) => {
  const [isFeatureEntered, setIsFeatureEntered] = useState(false);

  const handleEnterFeature = () => setIsFeatureEntered(true);

  const revealClass = isFeatureEntered ? 'reveal' : '';

  return (
    <Waypoint onEnter={handleEnterFeature} bottomOffset="35%">
      <li className={`feature hide ${revealClass}`}>
        <img src={feature.src} alt={feature.alt} className="feature__img" />
        <h3 className="feature__title">{feature.title}</h3>
        <p className="feature__description">{feature.description}</p>
      </li>
    </Waypoint>
  );
};

Feature.propTypes = {
  feature: PropTypes.object.isRequired,
};

export default Feature;
