import { DashMenuTypes } from './dash-menu-types';

const INITIAL_STATE = {
  isFixed: true,
  isOpen: false,
};

const dashMenuReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case DashMenuTypes.TOGGLE_MENU_OPEN:
      return { ...state, isOpen: !state.isOpen };
    case DashMenuTypes.TOGGLE_MENU_FIXED:
      return { ...state, isFixed: !state.isFixed };
    default:
      return state;
  }
};

export default dashMenuReducer;
