import React from 'react';

import './SignalTableRow.scss';

const SignalTableRow = ({ data, index, handleToggleModal }) => {
  const replaceZeroes = num => {
    const stringified = num.toString();
    if (num > 1000 && num <= 1000000) {
      return `${stringified.substring(0, stringified.length - 3)}K`;
    } else if (num > 1000000 && num <= 1000000000) {
      return `${stringified.substring(0, stringified.length - 6)}M`;
    } else {
      return stringified;
    }
  };

  const formatProfit = profitNum => {
    let formatted;

    if (profitNum < 0) formatted = `- $${replaceZeroes(Math.abs(profitNum))}`;
    else formatted = `+ $${replaceZeroes(profitNum)}`;

    return formatted;
  };

  const darkBgClass = index % 2 !== 0 ? '' : 'signal-table-row--dark';
  return (
    <div
      onClick={handleToggleModal}
      tabIndex={1}
      className={`signal-table-row ${darkBgClass}`}
    >
      <span className="signal-table-row__data signal-table-row__data--symbol">
        <span>{data.side}</span>
        {data.symbol}
      </span>
      <span className="signal-table-row__data signal-table-row__data--amount">
        {data.amount}
      </span>
      <span className="signal-table-row__data remove-at-sm">{data.date}</span>
      <span className="signal-table-row__data remove-at-sm">{data.status}</span>
      <span className="signal-table-row__data">
        {formatProfit(data.profit)}
      </span>
    </div>
  );
};

export default SignalTableRow;
