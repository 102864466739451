import React from 'react';

import PerformanceChart from './PerformanceChart';
import CoinAllocationChart from './CoinAllocationChart';
import TradeFreqChart from './TradeFreqChart';
import StatBox from './StatBox';
import DashComponent from '../../components/Dashboard/DashComponent';
import FilterForm from './FilterForm';

import { ReactComponent as PerformanceIcon } from '../../static/svg/performance-icon.svg';
import { ReactComponent as CoinAllocationIcon } from '../../static/svg/coin-allocation-icon.svg';
import { ReactComponent as TradeFreqIcon } from '../../static/svg/trade-freq-icon.svg';

import {
  statArray,
  profitData,
  allocationData,
  tradeFreqBarData,
} from './seedData';

import './DashHome.scss';

const DashHome = ({ role, handleToggleSignalModal }) => {
  const renderUserStatBoxes = statArray.map(stat => {
    let statBoxArray = [];
    if (stat.description !== 'trade of the day')
      statBoxArray.push(
        <StatBox role={role} key={stat.description} stat={stat} />,
      );
    return statBoxArray;
  });

  const renderNonUserStatBoxes = statArray.map(stat => {
    let statBoxArray = [];
    if (stat.description === 'net profit' || stat.description === 'trades')
      statBoxArray.push(
        <StatBox role={role} key={stat.description} stat={stat} />,
      );
    if (stat.description === 'trade of the day')
      statBoxArray.push(
        <StatBox
          role={role}
          key={stat.description}
          stat={stat}
          handleToggleSignalModal={handleToggleSignalModal}
        />,
      );
    return statBoxArray;
  });
  return (
    <section className="dash-home">
      <FilterForm role={role} />

      <div className="dash-home__stat-box-container">
        {role === 'user' ? renderUserStatBoxes : renderNonUserStatBoxes}
      </div>

      <DashComponent svg={<PerformanceIcon />} title="performance">
        <PerformanceChart data={profitData} />
      </DashComponent>
      <div className="dash-home__row">
        <DashComponent
          svg={<CoinAllocationIcon />}
          title="coin allocation"
          twoPerRow={true}
          role={role}
        >
          <CoinAllocationChart data={allocationData} />
        </DashComponent>
        <DashComponent
          svg={<TradeFreqIcon />}
          title="trade frequency"
          twoPerRow={true}
          role={role}
        >
          <TradeFreqChart data={tradeFreqBarData} />
        </DashComponent>
      </div>
    </section>
  );
};

export default DashHome;
