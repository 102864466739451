import React from 'react';

import DashComponent from '../../components/Dashboard/DashComponent';
import AccountStatus from './AccountStatus';
import BinanceForm from './BinanceForm';
import Notifications from './Notifications';
import BlacklistForm from './BlacklistForm';
import PasswordForm from './PasswordForm';
import TradeProfile from './TradeProfile';

import { ReactComponent as KeyIcon } from '../../static/svg/key-icon.svg';
import { ReactComponent as NotificationIcon } from '../../static/svg/notification-icon.svg';
import { ReactComponent as TradeProfileIcon } from '../../static/svg/trade-profile-icon.svg';
import { ReactComponent as LockIcon } from '../../static/svg/lock-icon.svg';
import { ReactComponent as CancelIcon } from '../../static/svg/cancel-icon.svg';

import './DashSettings.scss';

const notificationPreferences = {
  email: {
    active: true,
    linked: true,
    username: 'username21@email.com',
  },
  discord: {
    active: false,
    linked: false,
    username: '',
  },
  telegram: {
    active: false,
    linked: true,
    username: 'username21',
  },
};

const DashSettings = ({ role }) => (
  <section className="dash-settings">
    <AccountStatus
      profileIsSet={true}
      binanceIsLinked={true}
      accountIsActive={true}
      role={role}
    />
    <div className="dash-settings__row">
      <DashComponent
        svg={<KeyIcon />}
        title="binance"
        twoPerRow={true}
        role={role}
      >
        <BinanceForm binanceIsLinked={true} />
      </DashComponent>
      <DashComponent
        svg={<NotificationIcon />}
        title="notifications"
        twoPerRow={true}
        role={role}
      >
        <Notifications notificationPreferences={notificationPreferences} />
      </DashComponent>
    </div>
    <DashComponent svg={<TradeProfileIcon />} title="trade profile" role={role}>
      <TradeProfile />
    </DashComponent>
    <div className="dash-settings__row">
      <DashComponent
        svg={<LockIcon />}
        title="password"
        twoPerRow={true}
        role={role}
      >
        <PasswordForm />
      </DashComponent>
      <DashComponent
        svg={<CancelIcon />}
        title="coin blacklist"
        twoPerRow={true}
        role={role}
      >
        <BlacklistForm blacklistFromDB={['bnb', 'doge', 'xmr']} />
      </DashComponent>
    </div>
  </section>
);

export default DashSettings;
