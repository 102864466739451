import React from 'react';
import PropTypes from 'prop-types';

const SignalHistoryIcon = ({
  color = '979797',
  classes = 'dashboard-menu__link-icon',
}) => (
  <svg
    className={classes}
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.4652 14.5866C14.8943 14.1912 15.1673 13.6289 15.1673 12.9995C15.1673 11.8045 14.1966 10.8327 13.0005 10.8327C11.8023 10.8327 10.8338 11.8045 10.8338 12.9995C10.8338 13.6278 11.1068 14.1912 11.5358 14.5866L6.614 24.4324C6.44716 24.7671 6.46342 25.1669 6.66167 25.4865C6.85993 25.8061 7.2077 26 7.58363 26H18.4174C18.7933 26 19.1411 25.805 19.3394 25.4854C19.5376 25.1658 19.5539 24.7671 19.387 24.4313L14.4652 14.5866ZM14.4977 19.4997H11.5033L13.0005 16.5064L14.4977 19.4997ZM9.33653 23.8332L10.4199 21.6665H15.5811L16.6645 23.8332H9.33653Z"
      fill={`#${color}`}
    />
    <path
      d="M22.193 3.80807C19.7381 1.35206 16.4728 0 13.0005 0C9.52831 0 6.26301 1.35206 3.80808 3.80807C1.35206 6.26301 0 9.52723 0 12.9995C0 16.4717 1.35206 19.737 3.80808 22.193L5.33997 20.6611C3.29347 18.6157 2.16676 15.8932 2.16676 12.9995C2.16676 10.1047 3.29347 7.38539 5.33997 5.33997C7.38647 3.29347 10.1068 2.16567 13.0005 2.16567C15.8942 2.16567 18.6146 3.29239 20.6611 5.33997C22.7076 7.38647 23.8343 10.1058 23.8343 12.9995C23.8343 15.8932 22.7076 18.6135 20.6611 20.6589L22.193 22.1908C24.649 19.737 26.0011 16.4728 26.0011 12.9995C26.0011 9.52614 24.649 6.26301 22.193 3.80807Z"
      fill={`#${color}`}
    />
    <path
      d="M13.0005 5.41693C8.81976 5.41693 5.41687 8.81874 5.41687 13.0006C5.41687 13.9659 5.60104 14.9138 5.96289 15.8174L7.97473 15.0092C7.71472 14.3624 7.58363 13.6874 7.58363 12.9995C7.58363 10.0115 10.0136 7.5826 13.0005 7.5826C15.9874 7.5826 18.4174 10.0115 18.4174 12.9995C18.4174 13.6874 18.2863 14.3635 18.0285 15.0081L20.0381 15.8163C20.4 14.9127 20.5842 13.9659 20.5842 12.9995C20.5842 8.81766 17.1813 5.41693 13.0005 5.41693Z"
      fill={`#${color}`}
    />
  </svg>
);

SignalHistoryIcon.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.string,
};

export default SignalHistoryIcon;
