import React from 'react';
import PropTypes from 'prop-types';

const TradeInterfaceIcon = ({
  color = '979797',
  classes = 'dashboard-menu__link-icon',
}) => (
  <svg
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={classes}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M26.7461 13.617C26.6322 20.707 20.6919 26.5318 13.4778 26.6265C6.26648 26.7209 0.510873 21.0495 0.625687 13.9598C0.738634 6.86926 6.6788 1.04406 13.8909 0.949462C21.1046 0.854457 26.86 6.52669 26.7461 13.617C26.7461 13.617 26.86 6.52669 26.7461 13.617ZM18.8731 10.576C18.7325 8.85838 17.2314 8.30169 15.3271 8.16255L15.3654 5.77704L13.8881 5.79661L13.8509 8.11924C13.4625 8.1244 13.0654 8.13716 12.6709 8.15008L12.7087 5.81206L11.2322 5.83143L11.1933 8.21626C10.8733 8.22688 10.5592 8.23685 10.2529 8.24108L10.2528 8.23368L8.21526 8.25978L8.19071 9.8106C8.19071 9.8106 9.28197 9.77582 9.26351 9.7958C9.8619 9.78811 10.0512 10.127 10.1031 10.4209L10.0595 13.1385C10.1008 13.1382 10.1546 13.1391 10.2155 13.1466C10.1663 13.1473 10.1136 13.1476 10.0594 13.1488L9.99783 16.9559C9.96859 17.1413 9.85315 17.4379 9.43507 17.4441C9.45371 17.4603 8.36119 17.458 8.36119 17.458L8.03978 19.1959L9.96227 19.1706C10.32 19.1663 10.6719 19.1676 11.0175 19.1652L10.9793 21.5779L12.455 21.5587L12.4934 19.1716C12.8984 19.1745 13.2905 19.1725 13.6733 19.1671L13.6345 21.5433L15.1118 21.5237L15.151 19.1153C17.6368 18.9426 19.385 18.3048 19.6378 16.0095C19.8417 14.1613 18.9715 13.3493 17.5671 13.035C18.4305 12.5951 18.9781 11.8327 18.8731 10.576C18.8731 10.576 18.9781 11.8327 18.8731 10.576ZM16.7233 15.762C16.6951 17.565 13.5575 17.4012 12.5563 17.4153L12.6081 14.2186C13.6096 14.2057 16.7537 13.8814 16.7233 15.762C16.7233 15.762 16.7537 13.8814 16.7233 15.762ZM16.1088 11.261C16.0819 12.9013 13.4655 12.7444 12.6316 12.7554L12.6783 9.85619C13.5122 9.84516 16.1361 9.55006 16.1088 11.261C16.1088 11.261 16.1361 9.55006 16.1088 11.261Z"
      fill={`#${color}`}
    />
  </svg>
);

TradeInterfaceIcon.propTypes = {
  color: PropTypes.string,
  classes: PropTypes.string,
};

export default TradeInterfaceIcon;
