import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import SignalTableRow from './SignalTableRow';
import ActivityTableRow from './ActivityTableRow';

import './TableDisplay.scss';

const TableDisplay = ({
  numPages,
  match,
  handleToggleModal,
  headerTitleArray,
  removeHeaderAtSmallArray,
  tableRowsData,
  type,
  paginationPath,
}) => {
  const renderActivityTableRows = () =>
    tableRowsData.map((row, i) => (
      <ActivityTableRow
        key={row.username}
        data={row}
        index={i}
        handleToggleModal={handleToggleModal}
      />
    ));
  const renderSignalTableRows = () =>
    tableRowsData.map((row, i) => (
      <SignalTableRow
        key={i}
        handleToggleModal={handleToggleModal}
        data={row}
        index={i}
      />
    ));

  const renderTableHeaders = () =>
    headerTitleArray.map(title => {
      let header;

      if (removeHeaderAtSmallArray.includes(title))
        header = (
          <span
            key={title}
            className="table-display__header-title remove-at-sm"
          >
            {title}
          </span>
        );
      else if (!removeHeaderAtSmallArray.includes(title))
        header = (
          <span key={title} className="table-display__header-title">
            {title}
          </span>
        );
      return header;
    });

  const renderPagination = () => {
    let paginationArr = [];
    for (let i = 1; i <= numPages; i++) {
      if (match.params.page === i.toString())
        paginationArr.push(
          <span className="table-display__pagination-link current" key={i}>
            {i}
          </span>,
        );
      else {
        paginationArr.push(
          <Link
            className="table-display__pagination-link"
            key={i}
            to={`${paginationPath}/${i}`}
          >
            {i}
          </Link>,
        );
      }
    }
    return paginationArr;
  };

  return (
    <div className="table-display">
      <div className="table-display__table-header">{renderTableHeaders()}</div>
      <div className="table-display__table-body">
        {type === 'signal history'
          ? renderSignalTableRows()
          : renderActivityTableRows()}
      </div>
      <div className="table-display__pagination">{renderPagination()}</div>
    </div>
  );
};

export default withRouter(TableDisplay);
