import React from 'react';
import Button from '../../../components/Button';

import './Landing.scss';

const Landing = () => (
  <section className="landing" id="top">
    <div className="landing__content">
      <h1 className="landing__headline">
        Register an account.
        <br /> Start winning.
      </h1>
      <h2 className="landing__sub-headline">
        NO subscription plan. NO problem.
      </h2>
      <h2 className="landing__sub-headline">
        Our service is free until you see profits.
      </h2>
      <div className="landing__btn-container">
        <Button
          href="/auth/register"
          header={false}
          type="solid"
          element="link"
        >
          Start Winning
        </Button>
      </div>
    </div>
  </section>
);

export default Landing;
