import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { PropTypes } from 'prop-types';
import Recaptcha from 'react-recaptcha';
import TextField from '@material-ui/core/TextField';

import Button from '../../../components/Button';

import './AuthForm.scss';

const AuthForm = ({ match, history }) => {
  const { form } = match.params;
  const siteKey = '6LeDiqsUAAAAAEzbB9Xsl-aaTOqnzY6m3NK20lVE';
  const [reCaptchaIsLoaded, setReCaptchaIsLoaded] = useState(false);
  let fields, links, captcha;

  const handleFormSubmit = () => {
    history.push('/dashboard');
  };

  const handleRecaptchaLoad = () => {
    setReCaptchaIsLoaded(prevState => !prevState);
  };

  const verifyRecaptcha = token => {
    console.log(token);
  };

  // Change auth form component based on match.params.form value
  if (form === 'login') {
    fields = (
      <>
        <TextField
          id="email"
          type="text"
          variant="standard"
          label="email"
          fullWidth={true}
        />
        <TextField
          id="password"
          type="password"
          variant="standard"
          label="password"
          fullWidth={true}
        />
      </>
    );

    links = (
      <div className="auth-form__links-container">
        <Link className="auth-form__link" to="/auth/reset">
          Reset Password
        </Link>

        <span>
          <p className="auth-form__text">
            Not a member?{' '}
            <Link className="auth-form__link" to="/auth/register">
              Register
            </Link>
          </p>
        </span>
      </div>
    );

    captcha = null;
  }

  if (form === 'register') {
    fields = (
      <>
        <div className="auth-form__form-row">
          <TextField
            id="username"
            type="text"
            variant="standard"
            label="username"
            fullWidth={true}
          />
          <TextField
            id="email"
            type="text"
            variant="standard"
            label="email"
            fullWidth={true}
          />
        </div>
        <div className="auth-form__form-row">
          <TextField
            id="password"
            type="password"
            variant="standard"
            label="password"
            fullWidth={true}
          />
          <TextField
            id="confirmPassword"
            type="password"
            variant="standard"
            label="confirm password"
            fullWidth={true}
          />
        </div>
      </>
    );

    links = (
      <div className="auth-form__links-container">
        <span>
          <p className="auth-form__text">
            Already a member?{' '}
            <Link className="auth-form__link" to="/auth/login">
              Login
            </Link>
          </p>
        </span>
      </div>
    );

    captcha = (
      <Recaptcha
        sitekey={siteKey}
        render="explicit"
        theme="dark"
        verifyCallback={verifyRecaptcha}
        onloadCallback={handleRecaptchaLoad}
      />
    );
  }

  if (form === 'reset') {
    fields = (
      <TextField
        id="email"
        type="text"
        variant="standard"
        label="email"
        fullWidth={true}
      />
    );

    links = null;
    captcha = null;
  }
  return (
    <div className="auth-form">
      {form === 'success' ? (
        <div className="auth-form__success">
          <h2 className="sub-section-headline sub-section-headline--white">
            {form === 'reset' ? 'reset password' : form}
          </h2>
          <p className="auth-form__text auth-form__text--success">
            A reset link has been sent to{' '}
            <strong>
              <em>youremail@gmail.com</em>
            </strong>
            . Please follow the instructions in the email to reset your
            password.
          </p>
          <Button type="solid" href="/auth/login" element="link" header={false}>
            Login
          </Button>
        </div>
      ) : (
        <>
          <h2 className="sub-section-headline sub-section-headline--white">
            {form === 'reset' ? 'reset password' : form}
          </h2>
          <form onSubmit={handleFormSubmit} className="auth-form__form">
            {fields} {captcha}
            <Button type="solid" value={form} element="input" header={false} />
          </form>
        </>
      )}
      {links}
    </div>
  );
};

AuthForm.propTypes = {
  match: PropTypes.object.isRequired,
};

export default withRouter(AuthForm);
