import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';

import Button from '../../../components/Button';

import DeleteIcon from '../../../static/svg/delete-icon.svg';

import './BinanceForm.scss';

const BinanceForm = ({ binanceIsLinked }) => {
  const initialState = { apiKey: '', secret: '' };
  const [showForm, setShowForm] = useState(!binanceIsLinked);
  const [inputFields, setInputFields] = useState(initialState);

  const handleSubmitBinance = e => {
    // handle differently depending on the state of isUpdateForm
    e.preventDefault();
    setShowForm(prevState => !prevState);
    setInputFields(initialState);
  };

  const handleClearCredClick = e => {
    e.preventDefault();
    setShowForm(prevState => !prevState);
  };

  const handleInputChange = e => {
    e.persist();
    setInputFields(prevState => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  return (
    <div className="binance-form custom-mui-form">
      {showForm ? (
        <div className="binance-form__acount-linked">
          <span className="binance-form__account-linked-msg">
            Your secret and API key have been saved!
          </span>
          <div className="binance-form__btn-container">
            <button
              arial-label="delete"
              onClick={handleClearCredClick}
              className="binance-form__clear-btn"
            >
              <img src={DeleteIcon} alt="circle with a line through it" />
              Clear credentials
            </button>
          </div>
        </div>
      ) : (
        <form onSubmit={handleSubmitBinance} className="custom-mui-form__form">
          <TextField
            id="key"
            type="password"
            variant="standard"
            label="API Key"
            fullWidth={true}
            name="apiKey"
            onChange={handleInputChange}
            value={inputFields.apiKey}
          />
          <TextField
            id="secret"
            type="password"
            variant="standard"
            label="Secret"
            fullWidth={true}
            name="secret"
            onChange={handleInputChange}
            value={inputFields.secret}
          />
          <Button type="solid" header={false} element="input" value="save" />
        </form>
      )}
    </div>
  );
};

export default BinanceForm;
