import React from 'react';

import Landing from './Landing';
import Features from './Features';
import Stats from './Stats';
import Promo from './Promo';
import LowerCTA from './LowerCTA';
import ExchangeRate from './ExchangeRate';

import '../../scss/typography.scss';

const Home = () => (
  <main>
    <Landing />
    <Features />
    <Stats />
    <Promo />
    <LowerCTA />
    <ExchangeRate />
  </main>
);

export default Home;
